import React, {useEffect, useState} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import Tippy from '@tippyjs/react'
import {useTranslation} from 'react-i18next'
import {ButtonElement, CopyToClipboardBlock, DropdownSelector} from '../elements'
import {AppDispatch} from '../../store/store'
import {compactString, getDateString} from '../../utils/functions'
import {getToken, requestToken} from '../../store/tokensSlice'
import {checkWallet, getWalletAddress, setModalEditTicketPrices, setModalSendTransactions} from '../../store/appSlice'
import {getCoinsObject} from '../../store/coinsSlice'
import {IDropdownItem, IItemOnSale, ITicketPrice} from '../../store/types'
import {CHAINS, CUSTOM_DROPDOWN_ITEM, NULL_ADDRESS} from '../../utils/constants'
import {DiscountIcon, LoadingIcon} from '../icons'
import {buyAssetItem, cancelSale, editAssetItemPrice, getDiscount, requestDiscount} from '../../store/showcaseV2Slice'
import {GATracker} from '../GATracker'

interface PropsType {
    eventLink: string
    network?: string
    owner: string
    enableAfter: number | null
    disableAfter: number | null
    tokenCard: IItemOnSale
}

const ShowcaseV2TokenCard = ({eventLink, network, owner, tokenCard, enableAfter, disableAfter}: PropsType) => {
    const {t} = useTranslation()
    const address = tokenCard.nft.asset.contractAddress
    const tokenId = tokenCard.nft.tokenId
    let {showcaseName} = useParams()
    const [selectedPrice, setSelectedPrice] = useState(0)
    let coinsObject = useSelector(getCoinsObject)
    if (network) {
        coinsObject[NULL_ADDRESS] = {
            contract: NULL_ADDRESS,
            token: CHAINS[network].token,
            decimals: CHAINS[network].tokenPrecision,
        }
    }
    const discount = useSelector(getDiscount(`${address}-${tokenId}`))
    const token = useSelector(getToken(address, tokenId))
    const walletAddress = useSelector(getWalletAddress)
    let prices: IDropdownItem[] = []
    for (let i = 0; i < tokenCard.prices.length; i++) {
        let item = tokenCard.prices[i]
        if (coinsObject[item.payWith]) {
            let amount = item.amount
            if (discount) {
                const d = discount > 10000 ? 10000 : discount
                amount = amount.mul((10000 - d)).div(10000)
            }
            prices.push({
                name: `${ethers.utils.formatUnits(amount, coinsObject[item.payWith].decimals)} ${coinsObject[item.payWith].token}`,
                id: i
            })
        }
    }
    const currentDate = Math.floor(Date.now() / 1000)
    const showcaseDisabled = !!enableAfter && !!disableAfter && (enableAfter > currentDate || disableAfter < currentDate)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (network) {
            dispatch(requestToken({address, tokenId, network}))
            dispatch(requestDiscount({address, tokenId}))
        }
    }, [address, dispatch, network, tokenId])

    const buyHandler = () => {
        if (!showcaseName) {
            return
        }
        if (walletAddress === owner) {
            dispatch(checkWallet({
                request: cancelSale({
                    item: tokenCard,
                    priceIndex: selectedPrice,
                    showcaseName,
                }),
                network,
            }))
        } else {
            GATracker.sendEventTracker('Showcase', 'BuyClick', showcaseName)
            dispatch(checkWallet({
                request: buyAssetItem({
                    item: tokenCard,
                    priceIndex: selectedPrice,
                    eventLink,
                    showcaseName,
                    successText: <p>
                        {t('showcase.goToEvent1')}
                        {' '}
                        <NavLink to={eventLink} onClick={() => dispatch(setModalSendTransactions(null))}>
                            {t('showcase.eventPage')}
                        </NavLink>
                        {' '}
                        {t('showcase.goToEvent2')}
                    </p>,
                }),
                network,
            }))
        }
    }
    const editHandler = () => {
        dispatch(checkWallet({
            request: setModalEditTicketPrices({
                prices: tokenCard.prices,
                setPrices: (value: ITicketPrice[]) => {
                    dispatch(checkWallet({
                        request: editAssetItemPrice({
                            assetItem: tokenCard.nft,
                            newPrices: value.map(item => ({
                                amount: ethers.utils.parseUnits(item.price.toString(), item.decimals) as ethers.BigNumber,
                                payWith: item.token !== CUSTOM_DROPDOWN_ITEM.id ? item.token : item.customContract,
                            })),
                            oldPrices: tokenCard.prices,
                            showcaseName: showcaseName || '',
                        }),
                        network,
                    }))
                },
            }),
            network,
        }))
    }

    return <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
        <div className="item-card">
            <div className="px-3 py-3">
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <span>{compactString(address)}</span>
                        <CopyToClipboardBlock text={address}/>
                    </div>
                    <div>
                        <span className="text-muted">ID </span><span>{tokenId.toString()}</span>
                        <CopyToClipboardBlock text={tokenId.toString()}/>
                    </div>
                </div>
            </div>
            <div className="item-card__image">
                {discount ?
                    <Tippy
                        content={t('showcase.discount')}
                        appendTo={document.getElementsByClassName("wrapper")[0]}
                        trigger='mouseenter'
                        interactive={false}
                        arrow={false}
                        maxWidth={512}
                    >
                        <div className="item-card__discount"><DiscountIcon/></div>
                    </Tippy>
                    :
                    null
                }
                <div className="image-container">
                    {token && token.image ?
                        token.image.substring(token.image.lastIndexOf('.') + 1) === 'mp4' ?
                            <video
                                loop={true}
                                autoPlay={true}
                                muted={true}
                            >
                                <source src={token.image} type="video/mp4"/>
                            </video>
                            :
                            <img src={token.image} alt={''}/>

                        :
                        <LoadingIcon/>
                    }
                </div>
            </div>
            {token?.name ?
                <div className="px-3 pt-3">
                    <div className="w-100 text-truncate mb-1">
                        <Tippy
                            content={token.name}
                            appendTo={document.getElementsByClassName("wrapper")[0]}
                            trigger='mouseenter'
                            interactive={false}
                            arrow={false}
                            maxWidth={512}
                        >
                            <b>{token.name}</b>
                        </Tippy>
                    </div>
                    {token.level ?
                        <div className="w-100 text-truncate">
                            <Tippy
                                content={token.level.title}
                                appendTo={document.getElementsByClassName("wrapper")[0]}
                                trigger='mouseenter'
                                interactive={false}
                                arrow={false}
                                maxWidth={512}
                            >
                                <span className="text-muted">{token.level.title}</span>
                            </Tippy>
                        </div>
                        :
                        null
                    }
                </div>
                :
                null
            }
            <div className="px-3 py-3">
                <div className="mb-3">
                    <DropdownSelector list={prices} currentItem={selectedPrice} setItem={setSelectedPrice}/>
                </div>
                {tokenCard.prices[0] ?
                    owner === walletAddress ?
                        <div>
                            <div className="btn-group w-100">
                                <ButtonElement small outline className={'w-100'}
                                               onClick={buyHandler}>{t('button.cancelSale')}</ButtonElement>
                                <ButtonElement small className={'w-100'}
                                               onClick={editHandler}>{t('button.editPrices')}</ButtonElement>
                            </div>
                        </div>
                        :
                        <ButtonElement className={'w-100'} onClick={buyHandler} disabled={showcaseDisabled}>
                            {showcaseDisabled ?
                                currentDate < enableAfter ?
                                    t('button.saleStart', {name: getDateString(new Date(enableAfter * 1000))})
                                    :
                                    t('button.saleEnded')
                                :
                                tokenCard.prices[0].amount.gt(0) ? t('button.buy') : t('button.mint')
                            }
                        </ButtonElement>
                    :
                    null
                }
            </div>
        </div>
    </div>
}

export default ShowcaseV2TokenCard
