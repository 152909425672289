import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getModalVideo, setModalVideo} from '../../store/appSlice'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {IModalProps} from '../../store/types'

const ModalVideo = (props: IModalProps) => {
    const modalFade = useRef(null)
    const modal = useSelector(getModalVideo)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalVideo(null))
    }

    if (!modal) {
        closeModal()
        return null
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-xl fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Video"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-2">
                    <h3 className="modal-title">{modal.title}</h3>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body text-break">
                    <div className="video-container">
                        <iframe
                            width="560"
                            height="315"
                            src={modal.link}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalVideo
