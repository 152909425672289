import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalGenerateAIGift, setModalGenerateAIGift} from '../../store/appSlice'
import {ButtonElement, CheckboxElement, TextareaElement, VideoButton} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon, LoadingIcon} from '../icons'
import {MIN_PROMPT_LEN} from '../../utils/constants'
import {generateImage, getGeneratingImage} from '../../store/giftsSlice'
import {IModalProps} from '../../store/types'

const ModalGenerateAIGift = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [imageCheckbox, setImageCheckbox] = useState(true)
    const [metadataCheckbox, setMetadataCheckbox] = useState(false)
    const [prompt, setPrompt] = useState('')
    const [promptError, setPromptError] = useState('')
    const generatingImage = useSelector(getGeneratingImage)
    const modal = useSelector(getModalGenerateAIGift)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalGenerateAIGift(null))
    }
    const generateHandler = () => {
        if (!modal) {
            return
        }

        let error = false
        if (!imageCheckbox && !metadataCheckbox) {
            error = true
        }
        if (prompt.length < MIN_PROMPT_LEN) {
            error = true
            setPromptError(t('error.mustBeGreater', {name: t('form.label.prompt'), value: MIN_PROMPT_LEN - 1}))
        } else {
            setPromptError('')
        }

        if (error) {
            return
        }

        dispatch(generateImage({image: imageCheckbox, metadata: metadataCheckbox, prompt}))
    }
    const onChangePrompt = (value: string) => {
        if (value === '') {
            setPromptError(t('error.mustEnter', {name: t('form.label.prompt')}))
        } else if (value.length < MIN_PROMPT_LEN) {
            setPromptError(t('error.mustBeGreater', {name: t('form.label.prompt'), value: MIN_PROMPT_LEN - 1}))
        } else {
            setPromptError('')
        }
        setPrompt(value)
    }

    if (!modal) {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Generate AI gift"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
        id="aiImageModal"
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.generateAIGift')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pb-4">
                    <div className="mb-4">
                        <TextareaElement
                            errorText={promptError}
                            label={t('form.label.prompt')}
                            value={prompt}
                            onChange={onChangePrompt}
                            rows={5}
                            disabled={generatingImage}
                        />
                    </div>
                    <div className="row gx-4">
                        <div className="col-auto mb-2">
                            <CheckboxElement
                                checked={imageCheckbox}
                                onChange={(value) => setImageCheckbox(value.target.checked)}
                                label={t('form.label.aiGeneratedImage')}
                                disabled={generatingImage}
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <CheckboxElement
                                checked={metadataCheckbox}
                                onChange={(value) => setMetadataCheckbox(value.target.checked)}
                                label={t('form.label.aiGeneratedMetadata')}
                                disabled={generatingImage}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="col-12 col-md pe-4">
                        <VideoButton
                            link={'https://www.youtube.com/embed/j9z36Ly9EIA?si=pza2unSuEVtP6tpN'}
                            title={'How to create a gift in "AI-generated" mode?'}
                        />
                    </div>
                    <div className="col-12 col-md-auto me-0">
                        <ButtonElement
                            className={'flex-grow-1 flex-lg-grow-0'}
                            onClick={generateHandler}
                            disabled={generatingImage || (!imageCheckbox && !metadataCheckbox)}
                        >{generatingImage ? <LoadingIcon/> : t('button.generate')}</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalGenerateAIGift
