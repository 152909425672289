import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {checkWallet, setModalCreateGiftsCollection} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {checkNameString} from '../../utils/functions'
import {CloseIcon} from '../icons'
import {createGiftsCollection} from '../../store/giftsSlice'
import {IModalProps} from '../../store/types'

const ModalCreateGiftsCollection = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [factoryName, setFactoryName] = useState('')
    const [factoryNameError, setFactoryNameError] = useState('')
    const [factorySymbol, setFactorySymbol] = useState('')
    const [factorySymbolError, setFactorySymbolError] = useState('')

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalCreateGiftsCollection(false))
    }
    const createFactory = () => {
        let error = false
        const name = factoryName.trim()
        const symbol = factorySymbol.trim()

        if (name === '') {
            error = true
            setFactoryNameError(t('error.mustEnter', {name: t('form.label.giftsCollectionTitle')}))
        } else if (!checkNameString(name)) {
            error = true
            setFactoryNameError(`${t('error.wrong', {name: t('form.label.giftsCollectionTitle')})} (a-Z, 0-9, "-", ".", "_", "~", " ")`)
        } else {
            setFactoryNameError('')
        }

        if (symbol === '') {
            error = true
            setFactorySymbolError(t('error.mustEnter', {name: t('form.label.giftsCollectionTicker')}))
        } else if (!/^[a-zA-Z0-9]*$/.test(symbol)) {
            error = true
            setFactorySymbolError(`${t('error.wrong', {name: t('form.label.giftsCollectionTicker')})} (a-Z, 0-9)`)
        } else {
            setFactorySymbolError('')
        }

        if (error) {
            return
        }
        dispatch(checkWallet({
            request: createGiftsCollection({
                name: factoryName,
                symbol: factorySymbol,
            })
        }))
    }
    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        id="newEventModal"
        tabIndex={-1}
        aria-labelledby="Create a new event"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.createCollection')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row mb-3">
                        <div className="col-12 col-md-6 col-lg-8">
                            <div className="mb-4">
                                <InputElement
                                    errorText={factoryNameError}
                                    label={<>{t('form.label.giftsCollectionTitle')} <span className="text-muted">(a-Z, 0-9, "-", ".", "_", "~")</span></>}
                                    value={factoryName}
                                    onChange={(value) => {
                                        if (checkNameString(value)) {
                                            setFactoryName(value)
                                            setFactoryNameError('')
                                        } else {
                                            setFactoryNameError(`${t('error.wrong', {name: t('form.label.giftsCollectionTitle')})} (a-Z, 0-9, "-", ".", "_", "~", " ")`)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="mb-4">
                                <InputElement
                                    errorText={factorySymbolError}
                                    label={<>{t('form.label.giftsCollectionTicker')} <span className="text-muted">(a-Z, 0-9)</span></>}
                                    value={factorySymbol}
                                    onChange={(value) => {
                                        if (/^[a-zA-Z0-9]*$/.test(value)) {
                                            setFactorySymbol(value)
                                            setFactorySymbolError('')
                                        } else {
                                            setFactorySymbolError(`${t('error.wrong', {name: t('form.label.giftsCollectionTicker')})} (a-Z, 0-9)`)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={createFactory}
                    >{t('button.create')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateGiftsCollection
