import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
    getCollections,
    postMintCollectionAddress,
    requestMintCollectionsFromChain, setCollections,
} from '../../store/mintSlice'
import {AppDispatch} from '../../store/store'
import {
    checkWallet,
    getCurrentNetwork,
    getShowcaseType,
    getWalletAddress,
    sendRequestWithAuth, setShowcaseType
} from '../../store/appSlice'
import {ButtonElement, DropdownPlaceholder, DropdownSelector} from '../elements'
import {IDropdownItem, TShowcaseType} from '../../store/types'

const types: {[key: string]: TShowcaseType} = {
    Classic: 'Classic',
    Onchain: 'Onchain',
    Smart: 'Smart',
}
let showcaseTypeList: IDropdownItem[] = []
for (let key in types) {
    showcaseTypeList.push({id: key, name: key})
}

const AddMintCollection = () => {
    const {t} = useTranslation()
    const [collection, setCollection] = useState('')
    const collections = useSelector(getCollections)
    const currentNetwork = useSelector(getCurrentNetwork)
    const showcaseType = useSelector(getShowcaseType)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        console.log('useEffect')
        return () => {
            dispatch(setCollections(null))
        }
    }, [dispatch])
    useEffect(() => {
        dispatch(checkWallet({request: requestMintCollectionsFromChain(), network: currentNetwork || undefined}))
    }, [currentNetwork, dispatch, walletAddress])

    const collectionsList: IDropdownItem[] | undefined = collections?.map((item): IDropdownItem => {
        return {id: item.contractAddress, name: item.name}
    })

    const showcaseTypeHandler = (v: string) => {
        const type: TShowcaseType | null = types[v] || null
        dispatch(setShowcaseType(type))
    }
    const submitHandler = () => {
        dispatch(sendRequestWithAuth(postMintCollectionAddress({contractAddress: collection})))
    }

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <h1>{t('header.mintTickets')}</h1>
                </div>
            </div>
            {collectionsList ?
                <DropdownSelector list={collectionsList} setItem={setCollection} currentItem={collection}/>
                :
                <DropdownPlaceholder title={t('status.loading')}/>
            }
            <DropdownSelector list={showcaseTypeList} setItem={showcaseTypeHandler} currentItem={showcaseType}/>
            <ButtonElement onClick={submitHandler}>{t('button.add')}</ButtonElement>
        </div>
    </div>
}

export default AddMintCollection
