import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL} from '../utils/constants'
import {checkResponse} from './appSlice'
import {IEventStats, SliceResponse} from './types'

interface StaticticState {
    eventStats: IEventStats | undefined | null
}

const initialState: StaticticState = {
    eventStats: null,
}

export const requestEventStats = createAsyncThunk(
    'statistic/requestEventStats',
    async (eventId: number, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse<IEventStats | undefined> = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else if (isNaN(eventId)) {
            response.error = {text: i18next.t('error.wrongEventId')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}stats/events/${eventId}`, config)
                let stats: IEventStats | undefined = result.data.stats || undefined
                response.status = result.status
                response.data = stats
            } catch (e: any) {
                response.defaultData = undefined
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setEventStats(value))
        }
        dispatch(checkResponse(response))
    }
)

export const statisticSlice = createSlice({
    name: 'statistic',
    initialState,
    reducers: {
        setEventStats: (state, action: PayloadAction<IEventStats | undefined | null>) => {
            state.eventStats = action.payload
        },
    },
})

export const getEventStats = (state: RootState): IEventStats | undefined | null => state.statistic.eventStats

export const {
    setEventStats,
} = statisticSlice.actions

export default statisticSlice.reducer
