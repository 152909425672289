import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL} from '../utils/constants'
import {
    checkResponse,
    setModalAddEventToShowcase,
    setModalAddShowcaseToEvent, setModalCreateEvent, setModalCreateOnChainEvent,
    setModalEditEvent, setModalEditString, setModalUploadImage,
} from './appSlice'
import {
    DBEvent,
    DBEventWithOrganizer, DBShowcase, IEvent,
    IEventFilters,
    IEventsObject, ILink, IShowcaseToEvent, ISocialLink,
    SliceResponse
} from './types'
import {setToWalletStorage} from './storage'
import {setShowcaseEvents} from './launchpadSlice'
import {setShowcaseEvents as setLazyShowcaseEvents} from './lazyMintingSlice'
import {setShowcaseEvents as setShowcaseV2Events} from './showcaseV2Slice'
import {postTicketLevel} from './ticketsSlice'

interface EventsState {
    currentEventId: number | null
    events: DBEventWithOrganizer[] | null
    eventsByOrganizer: DBEventWithOrganizer[] | null
    eventsObject: IEventsObject //Object with events from different organizers
    selectedEventId: number | null
}

const initialState: EventsState = {
    currentEventId: null,
    events: null,
    eventsByOrganizer: null,
    eventsObject: {},
    selectedEventId: null,
}

export const addEventToShowcase = createAsyncThunk(
    'events/addEventToShowcase',
    async (linkTitle: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {modalAddEventToShowcase} = state.app
        const {jwt} = state.auth
        const {selectedEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !selectedEventId || !modalAddEventToShowcase) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            const {contract, network, showcaseName} = modalAddEventToShowcase
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {contract, name: showcaseName, network: Number(network), linkTitle}
                const result = await axios.post(`${API_URL}events/${selectedEventId}/showcases`, body, config)
                response.status = result.status
                response.successCallback = () => {
                    dispatch(setModalAddEventToShowcase(null))
                    dispatch(setShowcaseEvents(null))
                    dispatch(setLazyShowcaseEvents(null))
                    dispatch(setShowcaseV2Events(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const addShowcaseToEvent = createAsyncThunk(
    'events/addShowcaseToEvent',
    async (params: IShowcaseToEvent, {getState, dispatch}): Promise<void> => {
        const {contract, name, linkTitle, network} = params
        const state = getState() as RootState
        const {jwt} = state.auth
        const {currentEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !currentEventId) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {contract, name, network: Number(network), linkTitle}
                const result = await axios.post(`${API_URL}events/${currentEventId}/showcases`, body, config)
                response.status = result.status
                response.successCallback = () => {
                    dispatch(setModalAddShowcaseToEvent(false))
                    dispatch(setCurrentEventId(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const changeEventModeration = createAsyncThunk(
    'events/changeEventModeration',
    async (params: {eventId: number, moderation: boolean}, {dispatch, getState}): Promise<void> => {
        const {eventId, moderation} = params
        const state = getState() as RootState
        const {jwt, user} = state.auth

        let response: SliceResponse<null> = {}
        if (!jwt || !user?.manager) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else if (isNaN(eventId)) {
            response.error = {text: i18next.t('error.eventNotSelected')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.put(
                    `${API_URL}events/${eventId}/moderation`,
                    {moderation},
                    config
                )
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.setData = () => {
                dispatch(setCurrentEventId(null))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const delShowcaseFromEvent = createAsyncThunk(
    'events/delShowcaseFromEvent',
    async (params: {eventId: number, showcaseId: number}, {getState, dispatch}): Promise<void> => {
        const {eventId, showcaseId} = params
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse<null> = {}
        if (!jwt || isNaN(eventId) || isNaN(showcaseId)) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.delete(`${API_URL}events/${eventId}/showcases?id=${showcaseId}`, config)
                response.status = result.status
                response.successCallback = () => {
                    dispatch(setCurrentEventId(null))
                    dispatch(setShowcaseEvents(null))
                    dispatch(setLazyShowcaseEvents(null))
                    dispatch(setShowcaseV2Events(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const postEvent = createAsyncThunk(
    'events/postEvent',
    async (
        params: IEvent,
        {getState, dispatch}
    ): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {showcaseType} = state.app
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse<DBEventWithOrganizer> = {}
        if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtUserOrOrganizerNotFound')}
        } else if (!showcaseType) {
            response.error = {text: i18next.t('error.showcaseTypeNotSelected')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                let startTime = params.startTime || Date.now()
                let endTime = params.endTime || Date.now() + 60000
                const result = await axios.post(
                    `${API_URL}events/${selectedOrganizerId}`,
                    {...params, startTime, endTime, showcaseType},
                    config
                )
                response.status = result.status
                response.data = result.data.event
                response.setData = (value) => {
                    dispatch(setEvents(null))
                    dispatch(setEventsByOrganizer(null))
                    dispatch(setSelectedEventId(value.id))
                    if (showcaseType !== 'Onchain') {
                        dispatch(setModalCreateEvent(false))
                        dispatch(postTicketLevel({level: 0, title: 'Zero'}))
                    } else {
                        dispatch(setModalCreateOnChainEvent(false))
                    }
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const putEvent = createAsyncThunk(
    'events/putEvent',
    async (
        params: Omit<DBEvent, 'img' | 'moderation' | 'showcaseType'>,
        {dispatch, getState}
    ): Promise<void> => {
        const {id, title, url, description, startTime, endTime, types} = params
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse<null> = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {
                    title,
                    url,
                    description,
                    startTime: startTime.getTime(),
                    endTime: endTime.getTime(),
                    typeIds: types.map(item => item.id)
                }
                const result = await axios.put(`${API_URL}events/${id}`, body, config)
                response.status = result.status
                response.successCallback = () => {
                    dispatch(setModalEditEvent(false))
                    dispatch(setCurrentEventId(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const putEventImage = createAsyncThunk(
    'events/putEventImage',
    async (url: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {currentEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !currentEventId) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {url}
                const result = await axios.put(`${API_URL}events/${currentEventId}/image`, body, config)
                response.status = result.status
                response.successCallback = () => {
                    dispatch(setModalUploadImage(null))
                    dispatch(resetEventById(currentEventId))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const putEventTitle = createAsyncThunk(
    'events/putEventTitle',
    async (title: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !selectedEventId) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {title}
                const result = await axios.put(`${API_URL}events/${selectedEventId}/title`, body, config)
                response.status = result.status
                response.successCallback = () => {
                    dispatch(setModalEditString(null))
                    dispatch(setEventsByOrganizer(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestCurrentEvent = createAsyncThunk(
    'events/requestCurrentEvent',
    async ({organizerUrl, eventUrl}: { organizerUrl: string, eventUrl: string }, {dispatch}): Promise<void> => {
        let response: SliceResponse<DBEventWithOrganizer | null> = {}
        if (!organizerUrl || !eventUrl) {
            response.error = {text: i18next.t('error.organizerOrEventNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/url/${organizerUrl}/${eventUrl}`)
                let event: DBEventWithOrganizer | null = null
                if (result.data) {
                    event = {
                        ...result.data.event,
                        organizer: result.data.event.organizer.toLowerCase(),
                        startTime: new Date(result.data.event.startTime),
                        endTime: new Date(result.data.event.endTime),
                        showcases: result.data.event.showcases.map((item: any): DBShowcase => ({
                            id: item.id,
                            chain: Number(item.chain_id),
                            contract: item.contract.toLowerCase(),
                            name: item.name,
                            eventId: Number(item.event_id),
                            title: item.title,
                        }))
                    }
                }
                response.status = result.status
                response.data = event
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setCurrentEventId(value?.id || null))
            dispatch(setEventToObject(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEvent = createAsyncThunk(
    'events/requestEvent',
    async (eventId: number, {dispatch}): Promise<void> => {
        let response: SliceResponse<DBEventWithOrganizer | null> = {}
        if (isNaN(eventId)) {
            response.error = {text: i18next.t('error.wrongEventId')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/${eventId}`)
                let event: DBEventWithOrganizer | null = {
                    ...result.data.event,
                    startTime: new Date(result.data.event.startTime),
                    endTime: new Date(result.data.event.endTime),
                } || null
                response.status = result.status
                response.data = event
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setEventToObject(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventLinks = createAsyncThunk(
    'events/requestEventLinks',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentEventId, eventsObject} = state.events

        let response: SliceResponse<ILink[]> = {}
        if (!currentEventId || !eventsObject[currentEventId]) {
            response.error = {text: i18next.t('error.eventNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/${currentEventId}/links`)
                response.status = result.status
                let links: ILink[] = []
                for (let item of result.data.links || []) {
                    links.push({
                        title: item.title,
                        url: item.url,
                    })
                }
                response.data = links
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.setData = (value) => {
                dispatch(setEventLinks({id: currentEventId, links: value}))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventsByOrganizer = createAsyncThunk(
    'events/requestEventsByOrganizer',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {showcaseType} = state.app
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse<DBEventWithOrganizer[]> = {}
        if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtOrOrganizerNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(
                    `${API_URL}events/organizer/${selectedOrganizerId}${showcaseType ? `?showcaseType=${showcaseType}` : ''}`,
                    config
                )
                const events: DBEventWithOrganizer[] = result.data.events.map((item: DBEventWithOrganizer) => ({
                    ...item,
                    startTime: new Date(item.startTime),
                    endTime: new Date(item.endTime),
                }))
                response.status = result.status
                response.data = events
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setEventsByOrganizer(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventSocialLinks = createAsyncThunk(
    'events/requestEventSocialLinks',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentEventId, eventsObject} = state.events

        let response: SliceResponse<ISocialLink[]> = {}
        if (!currentEventId || !eventsObject[currentEventId]) {
            response.error = {text: i18next.t('error.eventNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/${currentEventId}/socials`)
                response.status = result.status
                let links: ISocialLink[] = []
                for (let item of result.data.links || []) {
                    links.push({
                        id: item.id,
                        url: item.url,
                        network: item.network,
                    })
                }
                response.data = links
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.setData = (value) => {
                dispatch(setEventSocialLinks({id: currentEventId, links: value}))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventTags = createAsyncThunk(
    'events/requestEventTags',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentEventId, eventsObject} = state.events

        let response: SliceResponse<string[]> = {}
        if (!currentEventId || !eventsObject[currentEventId]) {
            response.error = {text: i18next.t('error.eventNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/${currentEventId}/tags`)
                response.status = result.status
                response.data = result.data.tags || []
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.setData = (value) => {
                dispatch(setEventTags({id: currentEventId, tags: value}))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventsWithFilter = createAsyncThunk(
    'events/requestEventsWithFilter',
    async (filter: IEventFilters, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        let response: SliceResponse<DBEventWithOrganizer[]> = {}
        try {
            let queryArr: string[] = []
            if (filter.from || filter.to) {
                if (filter.from) {
                    queryArr.push(`from=${filter.from}`)
                }
                if (filter.to) {
                    queryArr.push(`to=${filter.to}`)
                }
            } else {
                if (filter.month !== undefined) {
                    queryArr.push(`month=${filter.month}`)
                }
                if (filter.year) {
                    queryArr.push(`year=${filter.year}`)
                }
            }
            if (filter.typeIds && filter.typeIds.length > 0) {
                queryArr.push(`typeIds=${filter.typeIds.join(',')}`)
            }
            const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
            const result = await axios.get(`${API_URL}events?${queryArr.join('&')}`, config)
            response.status = result.status
            response.data = result.data.events.map((item: DBEventWithOrganizer) => ({
                ...item,
                startTime: new Date(item.startTime),
                endTime: new Date(item.endTime),
            }))
        } catch (e: any) {
            response.defaultData = []
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const sendEventLinks = createAsyncThunk(
    'events/sendEventLinks',
    async (links: ILink[], {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {currentEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !currentEventId) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.put(`${API_URL}events/${currentEventId}/links`, {links}, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.afterCheckCallback = () => {
                dispatch(setEventLinks({id: currentEventId, links: null}))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        resetEventById: (state, action: PayloadAction<number>) => {
            let newState: IEventsObject = {}
            for (let key in state.eventsObject) {
                if (Number(key) !== action.payload) {
                    newState[key] = state.eventsObject[key]
                }
            }
            state.eventsObject = newState
        },
        resetState: (state) => {
            let key: keyof EventsState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCurrentEventId: (state, action: PayloadAction<number | null>) => {
            state.currentEventId = action.payload
        },
        setEventLinks: (state, action: PayloadAction<{id: number, links: ILink[] | null}>) => {
            if (state.eventsObject[action.payload.id]) {
                state.eventsObject[action.payload.id].links = action.payload.links || undefined
            }
        },
        setEvents: (state, action: PayloadAction<DBEventWithOrganizer[] | null>) => {
            state.events = action.payload
        },
        setEventsByOrganizer: (state, action: PayloadAction<DBEventWithOrganizer[] | null>) => {
            state.eventsByOrganizer = action.payload
        },
        setEventsObject: (state, action: PayloadAction<IEventsObject>) => {
            state.eventsObject = action.payload
        },
        setEventSocialLinks: (state, action: PayloadAction<{id: number, links: ISocialLink[] | null}>) => {
            if (state.eventsObject[action.payload.id]) {
                state.eventsObject[action.payload.id].socialLinks = action.payload.links || undefined
            }
        },
        setEventTags: (state, action: PayloadAction<{id: number, tags: string[] | null}>) => {
            if (state.eventsObject[action.payload.id]) {
                state.eventsObject[action.payload.id].tags = action.payload.tags || undefined
            }
        },
        setEventToObject: (state, action: PayloadAction<DBEventWithOrganizer | null>) => {
            if (action.payload) {
                state.eventsObject[action.payload.id] = action.payload
            }
        },
        setSelectedEventId: (state, action: PayloadAction<number | null>) => {
            state.selectedEventId = action.payload
            setToWalletStorage('eventId', action.payload)
        },
    },
})

export const getCurrentEventId = (state: RootState): number | null => state.events.currentEventId
export const getEvent = (eventId: number | null) => (state: RootState): DBEventWithOrganizer | null => {
    if (!eventId || !state.events.eventsObject[eventId]) {
        return null
    }
    return state.events.eventsObject[eventId]
}
export const getEvents = (state: RootState): DBEventWithOrganizer[] | null => state.events.events
export const getEventsByOrganizer = (state: RootState): DBEventWithOrganizer[] | null => state.events.eventsByOrganizer
export const getEventsObject = (state: RootState): IEventsObject => state.events.eventsObject
export const getSelectedEventId = (state: RootState): number | null => state.events.selectedEventId
export const getSelectedEventName = (state: RootState): string => {
    if (state.events.eventsByOrganizer && state.events.selectedEventId) {
        for (let item of state.events.eventsByOrganizer) {
            if (item.id === state.events.selectedEventId) {
                return item.title
            }
        }
//        state.events.selectedEventId = null
        setToWalletStorage('eventId', null)
    }
    return ''
}

export const {
    resetEventById,
    resetState,
    setCurrentEventId,
    setEventLinks,
    setEvents,
    setEventsByOrganizer,
    setEventsObject,
    setEventSocialLinks,
    setEventTags,
    setEventToObject,
    setSelectedEventId,
} = eventsSlice.actions

export default eventsSlice.reducer
