import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL} from '../utils/constants'
import {checkResponse, setModalEditEventTags} from './appSlice'
import {SliceResponse} from './types'
import {setEventTags} from './eventsSlice'

interface TagsState {
//    tags: string[] | null
}

const initialState: TagsState = {
//    tags: null,
}

/*
export const requestSocialNetworks = createAsyncThunk(
    'socials/requestSocialNetworks',
    async (_, {dispatch}): Promise<void> => {
        let response: SliceResponse<ISocialNetwork[]> = {}
        try {
            const result = await axios.get(`${API_URL}social/networks`)
            response.status = result.status
            let networks: ISocialNetwork[] = []
            for (let item of result.data.networks || []) {
                networks.push({id: item.id, logo: item.logo, title: item.title, url: item.url})
            }
            response.data = networks
        } catch (e: any) {
            response.defaultData = []
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setSocialNetworks(value))
        }
        dispatch(checkResponse(response))
    }
)
*/

export const putEventTags = createAsyncThunk(
    'tags/putEventTags',
    async (tags: string[], {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {currentEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !currentEventId) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.put(`${API_URL}events/${currentEventId}/tags`, {tags}, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.afterCheckCallback = () => {
                dispatch(setEventTags({id: currentEventId, tags: null}))
                dispatch(setModalEditEventTags(false))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
/*
        setSocialNetworks: (state, action: PayloadAction<ISocialNetwork[] | null>) => {
            state.socialNetworks = action.payload
        },
*/
    },
})

//export const getSocialNetworks = (state: RootState): ISocialNetwork[] | null => state.socials.socialNetworks

export const {
//    setSocialNetworks,
} = tagsSlice.actions

export default tagsSlice.reducer
