import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, NavLink, useParams} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork, getShowcaseType,
    getWalletAddress, sendRequestWithAuth,
    setModalAddEventToShowcase,
    setModalConfirmation,
    setModalEditShowcase, setRedirectPath, setShowcaseType
} from '../../store/appSlice'
import {CHAINS, SHOWCASE_TOKENS_ON_PAGE} from '../../utils/constants'
import {getCoins, requestCoins, requestCustomCoins} from '../../store/coinsSlice'
import {ShowcaseV2TokenCard} from '../tokens'
import {NotFound} from '../static'
import {getDateRangeString, getDisplayHash} from '../../utils/functions'
import {AddTicketIcon, BackIcon, CrossIcon, EditIcon, LinkIcon, LoadingIcon} from '../icons'
import {AlertElement, ButtonElement} from '../elements'
import {delShowcaseFromEvent} from '../../store/eventsSlice'
import {
    getShowcase,
    getShowcaseEvents, getShowcaseOnchainEvents,
    requestShowcase,
    requestShowcaseEvents, requestShowcaseOnchainEvents,
    setShowcase, setShowcaseEvents, setShowcaseOnchainEvents
} from '../../store/showcaseV2Slice'
import {TShowcaseType} from '../../store/types'
import {GATracker} from '../GATracker'

const Showcase = () => {
    const {t} = useTranslation()
    const {network, showcaseName} = useParams()
    let networkStr: string | undefined = `0x${Number(network).toString(16)}`
    if (!CHAINS[networkStr]) {
        networkStr = undefined
    }
    const [loadedShowcase, setLoadedShowcase] = useState(false)
    const [nextPage, setNextPage] = useState(1)
    const coins = useSelector(getCoins)
    const currentNetwork = useSelector(getCurrentNetwork)
    const showcase = useSelector(getShowcase(showcaseName || ''))
    const currentDate = Math.floor(Date.now() / 1000)
    const showcaseEvents = useSelector(getShowcaseEvents)
    const showcaseOnchainEvents = useSelector(getShowcaseOnchainEvents)
    const showcaseType = useSelector(getShowcaseType)
    const showcasesLink = `/${showcaseType}/showcases`
    const showcaseDisabled = !!showcase && (showcase.enableAfter > currentDate || showcase.disableAfter < currentDate)
    const walletAddress = useSelector(getWalletAddress)
    const datesString = showcase ? getDateRangeString(new Date(showcase.enableAfter * 1000), new Date(showcase.disableAfter * 1000)) : ''

    const dispatch = useDispatch<AppDispatch>()


    useEffect(() => {
        if (network && showcaseName) {
            GATracker.sendPageTracker(`/showcases/${network}/${showcaseName}`, `View showcase ${showcaseName}`)
        }
    }, [network, showcaseName])
    useEffect(() => {
        if (networkStr && showcase) {
            for (let key in CHAINS[networkStr].showcaseV2PriceModel) {
                const type = key as TShowcaseType
                if (showcase.priceModel === CHAINS[networkStr].showcaseV2PriceModel[type]) {
                    dispatch(setShowcaseType(type))
                    break
                }
            }
        }
        return () => {
            if (showcaseType) {
                dispatch(setShowcaseType(null))
            }
        }
    }, [dispatch, networkStr, showcase, showcaseType])
    useEffect(() => {
        console.log(`showcase name hash: ${getDisplayHash(showcaseName || '')}`)
        dispatch(requestCoins(networkStr))
        return () => {
            dispatch(setShowcase({name: showcaseName || '', showcase: null}))
        }
    }, [dispatch, networkStr, showcaseName])
    useEffect(() => {
        if (showcaseName) {
            if (loadedShowcase && !showcase) {
                setLoadedShowcase(false)
            } else if (!loadedShowcase && showcase && networkStr) {
                setLoadedShowcase(true)
                dispatch(requestCustomCoins({showcaseName, network: networkStr}))
            } else if (!loadedShowcase && !showcase) {
                dispatch(requestShowcase({showcaseName, network: networkStr}))
            }
        }
    }, [dispatch, loadedShowcase, networkStr, showcase, showcaseName])
    useEffect(() => {
        if (!showcaseType) {
            return
        }

        if (showcaseName && loadedShowcase && showcase && networkStr) {
            if (showcaseType !== 'Onchain' && !showcaseEvents) {
                dispatch(requestShowcaseEvents({
                    contract: CHAINS[networkStr].showcaseV2Contract,
                    displayName: showcaseName,
                    network: networkStr
                }))
            } else if (showcaseType === 'Onchain' && !showcaseOnchainEvents) {
                dispatch(requestShowcaseOnchainEvents({
                    contract: CHAINS[networkStr].showcaseV2Contract,
                    displayName: showcaseName,
                    network: networkStr
                }))
            }
        }
        if (showcaseType !== 'Onchain') {
            if (showcaseEvents) {
                return () => {
                    dispatch(setShowcaseEvents(null))
                }
            }
        } else {
            if (showcaseOnchainEvents) {
                return () => {
                    dispatch(setShowcaseOnchainEvents(null))
                }
            }
        }
    }, [dispatch, loadedShowcase, networkStr, showcase, showcaseEvents, showcaseName, showcaseOnchainEvents, showcaseType])

    const tokenList = useMemo(() => {
        if (!showcase) {
            return null
        }

        if (showcase.items.length === 0) {
            if (walletAddress === showcase.owner) {
                return <AlertElement centered type={'warning'}>
                    <Trans
                        i18nKey={'showcase.emptyShowcase'}
                        components={[<Link to={showcasesLink}/>, <a href={'#'}/>]}
                    />
                </AlertElement>
            } else {
                return <AlertElement centered type={'warning'}>{t('status.showcaseEmpty')}</AlertElement>
            }
        }

        let list: ReactNode[] = showcase.items.slice(0, SHOWCASE_TOKENS_ON_PAGE * nextPage).map((item) => (
            <ShowcaseV2TokenCard
                key={`${item.nft.asset.contractAddress}-${item.nft.tokenId}`}
                tokenCard={item}
                enableAfter={showcase.enableAfter}
                disableAfter={showcase.disableAfter}
                owner={showcase.owner}
                network={networkStr}
                eventLink={showcaseEvents && showcaseEvents[0] ? `/event/${showcaseEvents[0].organizerUrl}/${showcaseEvents[0].url}` : ''}
            />))
        if (showcase.items.length > SHOWCASE_TOKENS_ON_PAGE * nextPage) {
            list.push(<ButtonElement
                key={'loadMore'}
                outline
                onClick={() => {
                    setNextPage(nextPage + 1)
                }}
            >{t('button.loadMore')}</ButtonElement>)
        }
        return list
    }, [coins, currentNetwork, networkStr, nextPage, showcase, showcaseEvents, showcasesLink, t, walletAddress])

    if (!showcaseName) {
        return <NotFound/>
    }

    const addEventHandler = () => {
        if (networkStr && showcaseName) {
            dispatch(setModalAddEventToShowcase({
                contract: CHAINS[networkStr].showcaseV2Contract,
                network: networkStr,
                showcaseName,
            }))
        }
    }
    const addTicketsHandler = () => {
        dispatch(setRedirectPath(showcasesLink))
    }
    const delEventHandler = (eventId: number) => {
        let name = ''
        for (let item of showcaseEvents || []) {
            if (item.id === eventId) {
                name = item.title
                break
            }
        }
        let showcaseId = 0
        for (let item of showcaseEvents || []) {
            if (item.id === eventId) {
                for (let show of item.showcases || []) {
                    if (networkStr && show.chain === Number(networkStr) &&
                        show.contract === CHAINS[networkStr].showcaseV2Contract && show.name === showcaseName
                    ) {
                        showcaseId = show.id
                        break
                    }
                }
            }
        }
        if (name === '' || showcaseId === 0) {
            return
        }

        dispatch(setModalConfirmation({
            title: t('modal.question.deleteEventFromShowcase', {name}),
            confirmAction: () => {
                dispatch(sendRequestWithAuth(delShowcaseFromEvent({eventId, showcaseId})))
            },
        }))
    }
    const editShowcaseHandler = () => {
        dispatch(setModalEditShowcase(showcaseName || ''))
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb align-items-center">
                    <li className="breadcrumb-item">
                        <NavLink to={showcasesLink}>
                            <BackIcon className={'me-2 mt-n1'}/>
                            <span>{t('section.showcaseManagement')}</span>
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.view')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <div className="row mb-4 pb-4">
                <div className="col mb-4">
                    <h1 className="mb-3">{showcaseName}</h1>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <span className="text-muted me-2">{t('word.dates')}:</span>
                            <span>{datesString}</span>
                        </div>
                        <div className="col-auto">
                            <span className="text-muted me-2">{t('word.items')}:</span>
                            <span>{showcase?.items.length}</span>
                        </div>
                    </div>
                </div>
                {showcase?.owner === walletAddress ?
                    <div className="col-12 col-md-auto">
                        <div className="btn-group w-100">
                            <ButtonElement small outline onClick={editShowcaseHandler}>
                                <span className="me-2"><EditIcon/></span>
                                <span>{t('button.edit')}</span>
                            </ButtonElement>
                            <ButtonElement
                                small
                                onClick={addTicketsHandler}
                            >
                                <span className="me-2"><AddTicketIcon/></span>
                                <span>{t('button.addTickets')}</span>
                            </ButtonElement>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {showcaseDisabled ?
                <AlertElement centered type={'warning'}>{t('status.showcaseNotActive')}</AlertElement>
                :
                null
            }
            {showcase?.owner === walletAddress ?
                <div className="border rounded px-3 py-1 mb-2">
                    <div className="row gx-3 align-items-lg-center">
                        <div className="col py-1">
                            <div className="mt-1">
                                {showcaseType && showcaseType !== 'Onchain' ?
                                    showcaseEvents && showcaseEvents.length ?
                                        <>
                                            {t('showcase.goToEvent1')}
                                            {' '}
                                            {showcaseEvents.map((item, index) => (
                                                <span className="d-inline-block me-2" key={item.id}>
                                                    <NavLink to={`/event/${item.organizerUrl}/${item.url}`}>
                                                        {item.title}
                                                    </NavLink>
                                                    {' '}
                                                    {showcase?.owner === walletAddress ?
                                                        <ButtonElement
                                                            small
                                                            className={'btn-with-opacity p-0'}
                                                            onClick={() => delEventHandler(item.id)}
                                                        >
                                                            <CrossIcon/>
                                                        </ButtonElement>
                                                        :
                                                        index !== showcaseEvents.length - 1 ? ', ' : ''
                                                    }
                                                </span>
                                            ))}
                                            {' '}
                                            {t('showcase.goToEvent2')}
                                        </>
                                        :
                                        t('showcase.addEventDescription')
                                    :
                                    showcaseOnchainEvents && showcaseOnchainEvents.length ?
                                        <>
                                            {t('showcase.goToEvent1')}
                                            {' '}
                                            {showcaseOnchainEvents.map((item, index) => (
                                                <span className="d-inline-block me-2" key={item.id}>
                                                    <NavLink to={`/onchain/events/${networkStr}/${item.contractAddress}`}>
                                                        {item.name}
                                                    </NavLink>
                                                    {' '}
                                                    {showcase?.owner === walletAddress ?
                                                        <ButtonElement
                                                            small
                                                            className={'btn-with-opacity p-0'}
                                                            onClick={() => delEventHandler(item.id)}
                                                        >
                                                            <CrossIcon/>
                                                        </ButtonElement>
                                                        :
                                                        index !== showcaseOnchainEvents.length - 1 ? ', ' : ''
                                                    }
                                                </span>
                                            ))}
                                            {' '}
                                            {t('showcase.goToEvent2')}
                                        </>
                                        :
                                        t('showcase.addEventDescription')
                                }
                            </div>
                        </div>
                        {showcase?.owner === walletAddress ?
                            <div className="col-12 col-md-auto">
                                <ButtonElement
                                    small
                                    outline
                                    className={'w-100 my-3 my-md-2'}
                                    onClick={addEventHandler}
                                >
                                    <span className="me-2"><LinkIcon/></span>
                                    <span>{t('button.linkEvent')}</span>
                                </ButtonElement>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                showcaseEvents && showcaseEvents.length ?
                    <div className="alert alert-secondary">
                        {t('showcase.goToEvent1')}
                        {' '}
                        {showcaseEvents.map((item, index) => (
                            <span className="d-inline-block me-2" key={item.id}>
                                <NavLink to={`/event/${item.organizerUrl}/${item.url}`}>{item.title}</NavLink>
                                {index !== showcaseEvents.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                        {' '}
                        {t('showcase.goToEvent2')}
                    </div>
                    :
                    null
            }
            {showcase ?
                networkStr ?
                    <div className="row mt-4">{tokenList}</div>
                    :
                    <AlertElement centered type={'danger'}>{t('error.wrongUri')}</AlertElement>
                :
                <LoadingIcon/>
            }
        </div>
    </div>
}

export default Showcase
