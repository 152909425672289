import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {Contract} from 'web3-eth-contract'
import axios from 'axios'
import {ethers} from 'ethers'
import i18next from 'i18next'
import {RootState} from './store'
import {
    _AssetType,
    API_URL,
    CHAINS,
    MINT_FACTORY_BASE_URI,
} from '../utils/constants'
import {
    checkResponse, sendRequestWithAuth,
    setModalCreateMintCollection,
    setModalError,
    setModalMintTickets,
    setModalSendTransactions, setSelectedMintCollection
} from './appSlice'
import {
    ICollection,
    IProperty,
    ISendTransaction,
    SliceResponse,
} from './types'
import {getSelectedEventName, postEvent} from './eventsSlice'
import {getSelectedOrganizerName} from './organizersSlice'
import {saveMetadata} from '../utils/functions'
import {setBatch, setDescription, setTitle} from './inputSlice'

interface MintState {
    collections: ICollection[] | null
    mintContract: Contract | null
    mintedTokenId: number[] | null
    nftJsonUrl: string | null
    nftTxId: string | null
    nftTxSigned: boolean
    whitelistedTickets: IWhiteListedTickets | null
}

interface IWhiteListedTickets {
    added: number,
    exist: number,
    errors: number
}

interface TicketRequest {
    chain: number
    contract: string
    tokenId: number
    levelId: number
    assetType: number
}

const initialState: MintState = {
    collections: null,
    mintContract: null,
    mintedTokenId: null,
    nftJsonUrl: null,
    nftTxId: null,
    nftTxSigned: false,
    whitelistedTickets: null,
}

export const createMintCollection = createAsyncThunk(
    'mint/createMintCollection',
    async (
        {name, symbol}: { name: string, symbol: string },
        {dispatch, getState}
    ): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, showcaseType, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryContract721Abi, CHAINS[currentNetwork].nftFactoryContract721)
        const method = contract.methods.deployNewCollection(
            CHAINS[currentNetwork].nftFactoryImplContract721,
            walletAddress,
            name,
            symbol,
            MINT_FACTORY_BASE_URI
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].nftFactoryContract721,
                data: encodedABI,
            },
            title: i18next.t('action.createCollection'),
            successfulSendingCallback: async (receipt) => {
                dispatch(setModalCreateMintCollection(false))
                const collectionAddress = receipt?.logs[0].address.toLowerCase()
                if (collectionAddress) {
                    dispatch(setSelectedMintCollection(collectionAddress))
                    dispatch(sendRequestWithAuth(postMintCollectionAddress({contractAddress: collectionAddress})))
                }
                if (showcaseType !== 'Onchain') {
                    dispatch(sendRequestWithAuth(postEvent({name: `${name} event`})))
                }
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const mintNft = createAsyncThunk(
    'mint/mintNft',
    async (_, {dispatch, getState}): Promise<boolean> => {
        const showError = (title: string, text: string) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
            dispatch(setModalMintTickets(false))
            dispatch(resetMintState())
        }

        const state = getState() as RootState
        const {jwt} = state.auth
        const {currentNetwork, signer, walletAddress, web3} = state.app
        const {batch, description, externalUrl, loadedImage, properties, title} = state.input
        const {mintContract} = state.mint
        const {ticketLevelId} = state.tickets
        const eventName = getSelectedEventName(state)
        const organizerName = getSelectedOrganizerName(state)
        if (
            !batch.value || !currentNetwork || eventName === '' || !jwt || !loadedImage || !mintContract ||
            organizerName === '' || !signer || ticketLevelId === null || !web3 || !walletAddress
        ) {
            return false
        }

        dispatch(setModalMintTickets(true))
        let jsonIpfsLink = ''
        try {
            const attributes: IProperty[] = [
                {trait_type: i18next.t('form.label.organizerName'), value: organizerName},
                {trait_type: i18next.t('form.label.eventTitle'), value: eventName},
                ...properties.value
            ]
            jsonIpfsLink = await saveMetadata(title.value, description.value, attributes, loadedImage, externalUrl, jwt)
        } catch (e) {
            console.log(e)
            showError(i18next.t('error.mintError'), i18next.t('error.savingMetadata'))
            return false
        }
        dispatch(setNftJsonUrl(jsonIpfsLink))


        let addresses = []
        let tokenURIs = []
        for (let i = 0; i < batch.value; i++) {
            addresses.push(walletAddress)
            tokenURIs.push(jsonIpfsLink)
        }
        let mintedTokenIds: number[] = []
        try {
            const mintMethod = batch.value > 1 ?
                mintContract.methods.mintWithURIBatch(addresses, tokenURIs)
                :
                mintContract.methods.mintWithURI(walletAddress, jsonIpfsLink)
            const encodedABI = mintMethod.encodeABI()
            console.log(`sending transaction to ${CHAINS[currentNetwork].nftMinterContract721}`)
            const tx = await signer.sendTransaction({
                from: walletAddress,
                to: CHAINS[currentNetwork].nftMinterContract721,
                data: encodedABI,
            })
            dispatch(setNftTxSigned(true))

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    for (let log of receipt.logs) {
                        for (let item of CHAINS[currentNetwork].nftMinterContract721Abi) {
                            if (item.type !== 'event') {
                                continue
                            }
                            if (item.name !== 'Transfer') {
                                continue
                            }
                            const signature = item.name + '(' + item.inputs?.map((input) => input.type).join(',') + ')'
                            const hash = web3?.utils.sha3(signature)
                            if (hash === log.topics[0]) {
                                mintedTokenIds.push(Number(log.topics[3]))
                                break
                            }
                        }
                    }
                    dispatch(setNftTxId(receipt.transactionHash))
                }
            } catch (error: any) {
                console.log(error.receipt)
                showError(i18next.t('error.mintError'), i18next.t('error.mintErrorText'))
                return false
            }
        } catch (e: any) {
            const error = e.message || e.toString()
            console.log(e)
            showError(i18next.t('error.mintError'), error)
            return false
        }
        if (mintedTokenIds.length > 0) {
            dispatch(setMintedTokenId(mintedTokenIds))
            let tickets: TicketRequest[] = []
            for (let id of mintedTokenIds) {
                tickets.push({
                    chain: Number(currentNetwork),
                    contract: CHAINS[currentNetwork].nftMinterContract721,
                    tokenId: id,
                    assetType: _AssetType.ERC721,
                    levelId: ticketLevelId,
                })
            }
            dispatch(sendRequestWithAuth(sendTicketsToWhitelist(tickets)))
        }
        return true
    }
)

export const mintNftInCollection = createAsyncThunk(
    'mint/mintNftInCollection',
    async (_, {dispatch, getState}): Promise<boolean> => {
        const showError = (title: string, text: string) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
            dispatch(setModalMintTickets(false))
            dispatch(resetMintState())
        }

        const state = getState() as RootState
        const {jwt} = state.auth
        const {
            currentNetwork,
            selectedMintCollection,
            showcaseType,
            signer,
            walletAddress,
            web3,
        } = state.app
        const {
            batch,
            description,
            externalUrl,
            loadedImage,
            properties,
            title,
        } = state.input
        const {ticketLevelId} = state.tickets
        const eventName = getSelectedEventName(state)
        const organizerName = getSelectedOrganizerName(state)
        if (
            !batch.value || !currentNetwork || !jwt || !loadedImage || selectedMintCollection === null ||
            !ethers.utils.isAddress(selectedMintCollection) || !signer || !web3 || !walletAddress ||
            (showcaseType !== 'Onchain' && (eventName === '' || organizerName === '' || ticketLevelId === null))
        ) {
            return false
        }

        dispatch(setModalMintTickets(true))
        let jsonIpfsLink = ''
        try {
            const attributes: IProperty[] = [
                {trait_type: i18next.t('form.label.organizerName'), value: organizerName},
                {trait_type: i18next.t('form.label.eventTitle'), value: eventName},
                ...properties.value
            ]
            jsonIpfsLink = await saveMetadata(title.value, description.value, attributes, loadedImage, externalUrl, jwt)
        } catch (e) {
            console.log(e)
            showError(i18next.t('error.mintError'), i18next.t('error.savingMetadata'))
            return false
        }
        dispatch(setNftJsonUrl(jsonIpfsLink))

        let addresses = []
        let tokenURIs = []
        for (let i = 0; i < batch.value; i++) {
            addresses.push(walletAddress)
            tokenURIs.push(jsonIpfsLink)
        }
        let mintedTokenIds: number[] = []
        const mintContract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, selectedMintCollection)
        try {
            const mintMethod = batch.value > 1 ?
                mintContract.methods.mintWithURIBatch(addresses, tokenURIs)
                :
                mintContract.methods.mintWithURI(walletAddress, jsonIpfsLink)
            const encodedABI = mintMethod.encodeABI()
            console.log(`sending transaction to ${selectedMintCollection}`)
            const tx = await signer.sendTransaction({
                from: walletAddress,
                to: selectedMintCollection,
                data: encodedABI,
            })
            dispatch(setNftTxSigned(true))

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    for (let log of receipt.logs) {
                        for (let item of CHAINS[currentNetwork].nftFactoryImplContract721Abi) {
                            if (item.type !== 'event') {
                                continue
                            }
                            if (item.name !== 'Transfer') {
                                continue
                            }
                            const signature = item.name + '(' + item.inputs?.map((input) => input.type).join(',') + ')'
                            const hash = web3?.utils.sha3(signature)
                            if (hash === log.topics[0]) {
                                mintedTokenIds.push(Number(log.topics[3]))
                                break
                            }
                        }
                    }
                    dispatch(setNftTxId(receipt.transactionHash))
                }
            } catch (error: any) {
                console.log(error.receipt)
                showError(i18next.t('error.mintError'), i18next.t('error.mintErrorText'))
                return false
            }
        } catch (e: any) {
            const error = e.message || e.toString()
            console.log(e)
            showError(i18next.t('error.mintError'), error)
            return false
        }
        dispatch(setMintedTokenId(mintedTokenIds))
        if (mintedTokenIds.length > 0 && showcaseType !== 'Onchain' && ticketLevelId !== null) {
            let tickets: TicketRequest[] = []
            for (let id of mintedTokenIds) {
                tickets.push({
                    chain: Number(currentNetwork),
                    contract: selectedMintCollection,
                    tokenId: id,
                    assetType: _AssetType.ERC721,
                    levelId: ticketLevelId,
                })
            }
            console.log(ticketLevelId)
            if (showcaseType === 'Classic') {
                dispatch(sendRequestWithAuth(sendTicketsToWhitelist(tickets)))
            } else {
                dispatch(sendRequestWithAuth(sendTicketsToWhitelist([{
                    chain: Number(currentNetwork),
                    contract: selectedMintCollection,
                    tokenId: -1,
                    assetType: _AssetType.ERC721,
                    levelId: ticketLevelId,
                }])))
            }
        } else {
            dispatch(setWhiteListedTickets({added: 0, errors: 0, exist: 0}))
        }
        dispatch(setBatch(null))
        dispatch(setDescription(''))
        dispatch(setTitle(''))
        return true
    }
)

export const postMintCollectionAddress = createAsyncThunk(
    'mint/postMintCollectionAddress',
    async (
        params: Required<Pick<ICollection, 'contractAddress'>>,
        {getState, dispatch}
    ): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, showcaseType} = state.app
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse<null> = {}
        if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtUserOrOrganizerNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.post(`${API_URL}collections/mint/${selectedOrganizerId}`, {
                    showcaseType: showcaseType,
                    address: params.contractAddress,
                    network: Number(currentNetwork),
                }, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => dispatch(setCollections(null))
        dispatch(checkResponse(response))
    }
)

export const requestMintCollections = createAsyncThunk(
    'mint/requestMintCollections',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, showcaseType} = state.app
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse<ICollection[]> = {}
        if (!currentNetwork) {
            response.error = {text: i18next.t('error.networkNotSelected')}
        } else if (!jwt || !selectedOrganizerId) {
            console.log('requestMintCollections')
            response.error = {text: i18next.t('error.jwtOrOrganizerNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(
                    `${API_URL}collections/mint/${currentNetwork}/${selectedOrganizerId}${showcaseType ? `?showcaseType=${showcaseType}` : ''}`,
                    config
                )
                let collections: ICollection[] = []
                for (let item of result.data.collections) {
                    collections.push({
                        assetType: _AssetType.ERC721,
                        createdAt: Number(item.createdAt),
                        contractAddress: item.contract,
                        name: item.name,
                        showcaseType: item.showcaseType,
                    })
                }
                response.status = result.status
                response.data = collections.sort((a, b) => (a.createdAt - b.createdAt))
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setCollections(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestMintCollectionsFromChain = createAsyncThunk(
    'mint/requestMintCollectionsFromChain',
    async (_, {getState}): Promise<ICollection[] | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryContract721Abi, CHAINS[currentNetwork].nftFactoryContract721)
            const result = await contract.methods.getUsersCollections(walletAddress).call()
            let collections: ICollection[] = []
            for (let item of result) {
                if (Number(item.assetType) !== _AssetType.ERC721) {
                    continue
                }

                const cntr = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, item.contractAddress)
                const name = await cntr.methods.name().call()
                collections.push({
                    assetType: Number(item.assetType),
                    contractAddress: item.contractAddress.toLowerCase(),
                    createdAt: 0,
                    name,
                    showcaseType: 'Classic',
                })
            }
            return collections
        } catch (e) {
            console.log(e)
        }
        return []
    }
)

export const sendTicketsToWhitelist = createAsyncThunk(
    'mint/sendTicketsToWhitelist',
    async (tickets: TicketRequest[], {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events
        const {ticketLevelId} = state.tickets

        let response: SliceResponse<IWhiteListedTickets | null> = {}
        if (!jwt || !selectedEventId || ticketLevelId === null) {
            response.error = {text: i18next.t('error.jwtEventOrLevelNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.post(`${API_URL}tickets/${selectedEventId}/whitelist`, {tickets}, config)
                response.status = result.status
                response.data = result.data
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setWhiteListedTickets(value))
        }
        dispatch(checkResponse(response))
    }
)

export const mintSlice = createSlice({
    name: 'mint',
    initialState,
    reducers: {
        resetMintState: (state) => {
            state.nftJsonUrl = null
            state.nftTxId = null
            state.nftTxSigned = false
            state.mintedTokenId = null
            state.whitelistedTickets = null
        },
        resetState: (state) => {
            let key: keyof MintState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCollections: (state, action: PayloadAction<ICollection[] | null>) => {
            state.collections = action.payload
        },
        setMintContract: (state, action: PayloadAction<Contract | null>) => {
            state.mintContract = action.payload
        },
        setMintedTokenId: (state, action: PayloadAction<number[] | null>) => {
            state.mintedTokenId = action.payload
        },
        setNftJsonUrl: (state, action: PayloadAction<string | null>) => {
            state.nftJsonUrl = action.payload
        },
        setNftTxId: (state, action: PayloadAction<string | null>) => {
            state.nftTxId = action.payload
        },
        setNftTxSigned: (state, action: PayloadAction<boolean>) => {
            state.nftTxSigned = action.payload
        },
        setWhiteListedTickets: (state, action: PayloadAction<IWhiteListedTickets | null>) => {
            state.whitelistedTickets = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestMintCollectionsFromChain.fulfilled, (state, action: PayloadAction<ICollection[] | null>) => {
            state.collections = action.payload
        })
    },
})

export const getCollections = (state: RootState) => state.mint.collections
export const getMintedTokenId = (state: RootState) => state.mint.mintedTokenId
export const getNftJsonUrl = (state: RootState) => state.mint.nftJsonUrl
export const getNftTxId = (state: RootState) => state.mint.nftTxId
export const getNftTxSigned = (state: RootState) => state.mint.nftTxSigned
export const getWhitelistedTickets = (state: RootState) => state.mint.whitelistedTickets

export const {
    resetMintState,
    resetState,
    setCollections,
    setMintContract,
    setMintedTokenId,
    setNftJsonUrl,
    setNftTxId,
    setNftTxSigned,
    setWhiteListedTickets,
} = mintSlice.actions

export default mintSlice.reducer
