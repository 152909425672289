import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {applyTicket, getTicketInviteLink, requestInviteLink} from '../../store/ticketsSlice'
import {AppDispatch} from '../../store/store'
import {ButtonElement, CopyToClipboardBlock} from '../elements'
import {sendRequestWithAuth, setModalConfirmation} from '../../store/appSlice'
import {getEvent, requestEvent} from '../../store/eventsSlice'
import {compactString} from '../../utils/functions'
import {CHAINS} from '../../utils/constants'
import {ITicket} from '../../store/types'
import {ThemeContext} from '../../context'
import {LoadingIcon, TicketIcon} from '../icons'
import {GATracker} from '../GATracker'

interface PropsType {
    item: ITicket
}

const EventTicketItem = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [loadingStatus, setLoadingStatus] = useState(false)
    const event = useSelector(getEvent(item.eventId))
    const inviteLink = useSelector(getTicketInviteLink(item.ticketId))

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!event && item.isTicket) {
            dispatch(requestEvent(item.eventId))
        }
    }, [])
    useEffect(() => {
        setLoadingStatus(false)
        if (item.isUsed) {
            dispatch(sendRequestWithAuth(requestInviteLink(item.ticketId)))
        }
    }, [item])

    const useTicketHandler = () => {
        GATracker.sendEventTracker('Event', 'UseTicket', `${event?.organizerUrl}/${event?.url}`)
        dispatch(setModalConfirmation({
            title: t('modal.question.useTicket'),
            confirmAction: () => {
                setLoadingStatus(true)
                dispatch(sendRequestWithAuth(applyTicket({ticketId: item.ticketId, tokenId: item.tokenId})))
            },
        }))
    }

    return <div className="row-table__item mb-4 mb-md-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-5 col-md-auto order-2 order-md-1">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x${Number(item.network).toString(16)}`]?.iconSvg}`}
                    alt={CHAINS[`0x${Number(item.network).toString(16)}`]?.label}
                />
            </div>
            <div className="mb-2 col-5 col-md-auto order-3 order-md-0">
                <span className="d-md-none text-muted me-2">Type:</span>
                <span
                    className={`icon-${item.isTicket ? (item.isUsed ? 'ticket' : 'ticket-active') : 'empty'}`}>
                                <TicketIcon/>
                            </span>
            </div>
            <div className="mb-2 col-5 col-md order-1 order-md-2 col-md-max-140">
                <span>{compactString(item.contract)}</span>
                <CopyToClipboardBlock text={item.contract}/>
            </div>
            <div className="mb-2 col-5 col-md order-3">
                <span>{compactString(item.tokenId.toString())}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="mb-2 col-12 col-md-auto mt-2 mt-md-0 order-4">
                {item.isUsed ?
                    inviteLink ?
                        <div className="btn-group">
                            <a
                                href={inviteLink}
                                target={'_blank'}
                                rel={'noreferrer'}
                                className="btn btn-sm btn-outline-primary w-100"
                            >{t('button.joinToGroup')}</a>
                            <CopyToClipboardBlock
                                text={inviteLink}
                                small
                                additionalClass={'btn-outline-primary px-2 d-md-flex'}
                            />
                        </div>
                        :
                        <ButtonElement small className={'w-100'} disabled>
                            <LoadingIcon className={'me-2'}/>{t('status.loadingLink')}...
                        </ButtonElement>
                    :
                    <ButtonElement
                        small
                        className={'w-100'}
                        onClick={useTicketHandler}
                        disabled={loadingStatus}
                    >
                        {loadingStatus ?
                            <><LoadingIcon className={'me-2'}/>{t('status.sendingRequest')}...</>
                            :
                            t('button.useTicket')
                        }
                    </ButtonElement>
                }
            </div>
        </div>
    </div>
}

export default EventTicketItem
