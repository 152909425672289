import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {AppDispatch} from '../../store/store'
import {getEventsByOrganizer, requestEventsByOrganizer, setEventsByOrganizer} from '../../store/eventsSlice'
import {AlertElement, ButtonElement, NoteElement} from '../elements'
import {ENVIRONMENT} from '../../utils/constants'
import {EventCardWithLink} from './index'
import {getShowcaseType, sendRequestWithAuth, setModalCreateEvent} from '../../store/appSlice'
import {DBEventWithOrganizer} from '../../store/types'
import {LoadingIcon} from '../icons'
import {SelectOrganizer} from '../profile'
import {getFromStorage, setToStorage} from '../../store/storage'
import {getSelectedOrganizerId} from '../../store/organizersSlice'

const Events = () => {
    const {t} = useTranslation()
    const [hideNote, setHideNote] = useState(getFromStorage('hideEventsNote'))
    const events = useSelector(getEventsByOrganizer)
    const organizerId = useSelector(getSelectedOrganizerId)
    const showcaseType = useSelector(getShowcaseType)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!events && organizerId) {
            dispatch(sendRequestWithAuth(requestEventsByOrganizer()))
        }
    }, [dispatch, events, organizerId])
    useEffect(() => {
        return () => {
            dispatch(setEventsByOrganizer(null))
        }
    }, [dispatch, showcaseType])

    const eventsList = useMemo(() => {
        if (events === null) {
            return <LoadingIcon/>
        }

        let filtered: DBEventWithOrganizer[] = events
        if (!filtered || filtered.length === 0) {
            return <AlertElement centered>{t('status.noEvents')}</AlertElement>
        }

        return <div className="row">
            {filtered.map((item) => (
                <EventCardWithLink event={item} link={`/event/${item.organizerUrl}/${item.url}`} key={item.id}/>
            ))}
        </div>
    }, [events, t])

    if (!showcaseType) {
        return null
    }

    const addEventHandler = () => {
        dispatch(setModalCreateEvent(true))
    }
    const hideHandler = () => {
        setHideNote('hide')
        setToStorage('hideEventsNote', 'hide')
    }

    return <div className="page-content">
        {ENVIRONMENT === 'prod' && showcaseType === 'Smart' ?
            <Helmet>
                <title>{t(`meta.title.${showcaseType}Events`)}</title>
                <meta name='description' content={t(`meta.description.${showcaseType}Events`)}/>
            </Helmet>
            :
            null
        }
        <div className="container">
            <div className="text-primary mb-1">{t(`menu.${showcaseType}`)}</div>
            <h1>{t(`header.${showcaseType}Events`)}</h1>
            {!hideNote ? <NoteElement crossAction={hideHandler}>{t('event.note')}</NoteElement> : null}
            <div className="row">
                <div className="col-9 col-md-8 col-lg-6 col-xl-5">
                    <div className="mb-3"><SelectOrganizer/></div>
                </div>
            </div>
            <div className="row gx-2 gx-md-3 mb-3">
                <div
                    className="col-md-auto me-md-0 ms-md-auto mb-4 mb-md-0 pb-4 pb-md-0 mt-n3 mt-md-0 order-md-4">
                    <ButtonElement small className={'w-100'} onClick={addEventHandler}>
                        {t('button.addEvent')}
                    </ButtonElement>
                </div>
            </div>
            {eventsList}
        </div>
    </div>
}

export default Events
