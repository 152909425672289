import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalEditSocialLinks} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {CloseIcon} from '../icons'
import {IModalProps, ISocialLink} from '../../store/types'
import {getSocialNetworks, requestSocialNetworks, sendSocialLinks} from '../../store/socialsSlice'
import {AppDispatch} from '../../store/store'
import {getCurrentEventId, getEvent} from '../../store/eventsSlice'

const ModalEditSocialLinks = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const socialNetworks = useSelector(getSocialNetworks)
    let obj: {[key: string]: string} = {}
    for (let item of event?.socialLinks || []) {
        obj[item.network.id] = item.url
    }
    const [links, setLinks] = useState(obj)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!socialNetworks) {
            dispatch(requestSocialNetworks())
        }
    }, [dispatch, socialNetworks])

    const changeLinkHandler = (id: number, value: string) => {
        setLinks((prevState) => {
            let newState = {...prevState}
            let url = value
            const pos = url.indexOf('?')
            if (pos >= 0) {
                url = url.substring(0, pos)
            }
            newState[id] = url
            return newState
        })
    }
    const closeModal = () => {
        dispatch(setModalEditSocialLinks(false))
    }
    const saveLinks = () => {
        let linksArr: Pick<ISocialLink, 'id' | 'url'>[] = []
        for (let key in links) {
            linksArr.push({id: Number(key), url: links[key]})
        }
        dispatch(sendSocialLinks(linksArr))
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit social networks"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.socialLinks')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body">
                    {socialNetworks?.map((item, index) => {
                        return <div className="row gx-3 align-items-center" key={index}>
                            <div className="d-none d-sm-block col-sm-auto">
                                <div className="mb-2 mb-lg-3">
                                    <img src={`/static/img/socials/${item.logo}`} alt={item.title}/>
                                </div>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-3">
                                <div className="mb-2 mb-lg-3 text-end">
                                    <span className="text-muted">{item.url}</span>
                                </div>
                            </div>
                            <div className="col-6 col-sm">
                                <div className="mb-2 mb-lg-3">
                                    <InputElement
                                        value={links[item.id]}
                                        placeholder={t('form.label.userId')}
                                        onChange={(value) => {
                                            changeLinkHandler(item.id, value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="modal-footer">
                    <div className="col-12 col-md-auto">
                        <ButtonElement
                            className={'w-100'}
                            onClick={saveLinks}
                        >{t('button.save')}</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditSocialLinks
