import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import {useTranslation} from 'react-i18next'
import {getCurrentNetwork, getModalSendToken, setModalSendToken} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {sendToken} from '../../store/tokensSlice'
import {checkENSName} from '../../utils/functions'
import {getName} from '../../store/ensSlice'
import {CloseIcon} from '../icons'
import {IModalProps} from '../../store/types'

const ModalSendToken = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [receiverAddress, setReceiverAddress] = useState('')
    const [receiverAddressError, setReceiverAddressError] = useState('')
    const currentNetwork = useSelector(getCurrentNetwork)
    const modalSendToken = useSelector(getModalSendToken)
    const nameAddress = useSelector(getName(receiverAddress))

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalSendToken(null))
    }
    const sendHandler = () => {
        let error = false
        if (!checkENSName(receiverAddress, currentNetwork) && (receiverAddress === '' || !ethers.utils.isAddress(receiverAddress))) {
            error = true
            setReceiverAddressError(t('error.pasteRightAddress'))
        } else {
            setReceiverAddressError('')
        }
        if (error) {
            return
        }

        let to = receiverAddress
        if (checkENSName(to, currentNetwork) && nameAddress && ethers.utils.isAddress(nameAddress)) {
            to = nameAddress
        }
        dispatch(sendToken(to))
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Send token"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.sendToken')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        <span className="text-muted me-1">{t('form.label.contract')}</span>
                        <b>{modalSendToken?.contract}</b>
                    </div>
                    <div className="mb-3">
                        <span className="text-muted me-1">ID</span>
                        <b>{modalSendToken?.tokenId.toString()}</b>
                    </div>
                    <div>
                        <InputElement
                            errorText={receiverAddressError}
                            label={t('form.label.sendTo')}
                            value={receiverAddress}
                            onChange={setReceiverAddress}
                            isAddress={true}
                            setError={setReceiverAddressError}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={sendHandler}
                    >{t('button.send')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalSendToken
