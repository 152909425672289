import React, {useEffect, useMemo, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import {Trans, useTranslation} from 'react-i18next'
import {ethers} from 'ethers'
import {
    getCurrentEventId,
    getEvent, putEventImage,
    requestCurrentEvent, requestEventLinks, requestEventSocialLinks, requestEventTags, resetEventById,
    setCurrentEventId
} from '../../store/eventsSlice'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getWalletAddress, sendRequestWithAuth,
    setModalAddShowcaseToEvent,
    setModalEditEvent,
    setModalEditString, setShowcaseType,
    setModalShowShowcases, setModalEditSocialLinks, setModalEditEventTags, setModalUploadImage, setModalEditLinks
} from '../../store/appSlice'
import {getUserTicketsByEvent, requestUserTicketsByEvent, setUserTicketsByEvent} from '../../store/ticketsSlice'
import {EventTicketItem} from '../tickets'
import {APP_URL, TG_AUTH_BOT, VAR_KEYS} from '../../utils/constants'
import {AddIcon, EditIcon, LoadingIcon} from '../icons'
import {AlertElement, ButtonElement, CopyToClipboardBlock, ShareElement, TextareaElement} from '../elements'
import {getVariable, requestVariable, sendVariable, setVariable} from '../../store/variablesSlice'
import {ShowcaseRow} from './index'
import {compactString, createIpfsLink, getDateRangeString, getDateTime} from '../../utils/functions'
import {putOrganizerTitle} from '../../store/organizersSlice'
import {GATracker} from '../GATracker'

const Event = () => {
    const {t} = useTranslation()
    const {organizerUrl, eventUrl} = useParams()
    const [bannerLoaded, setBannerLoaded] = useState(false)
    const [editingTicketDescription, setEditingTicketDescription] = useState(false)
    const [loadingTickets, setLoadingTickets] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [ticketDescription, setTicketDescription] = useState('')
    const currentNetwork = useSelector(getCurrentNetwork)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const eventTicketDescription = useSelector(getVariable(`${VAR_KEYS.EventTicketDescription}-${currentEventId}`))
    const userTickets = useSelector(getUserTicketsByEvent)
    const walletAddress = useSelector(getWalletAddress)
    let startDate = ''
    let startTime = ''
    let endDate = ''
    let endTime = ''
    let eventDate = ''
    if (event) {
        eventDate = getDateRangeString(event.startTime, event.endTime)
        let datetime = getDateTime(event.startTime, true)
        startDate = datetime.date
        startTime = datetime.time
        datetime = getDateTime(event.endTime, true)
        endDate = datetime.date
        endTime = datetime.time
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (organizerUrl && eventUrl) {
            GATracker.sendPageTracker(`/event/${organizerUrl}/${eventUrl}`, `View event ${organizerUrl}/${eventUrl}`)
        }
    }, [organizerUrl, eventUrl])
    useEffect(() => {
        if (event && event.showcaseType) {
            dispatch(setShowcaseType(event.showcaseType))
        } else {
            dispatch(setShowcaseType(null))
        }
    }, [dispatch, event])
    const hasLinks = !event || !!event.links
    useEffect(() => {
        if (!hasLinks) {
            dispatch(requestEventLinks())
        }
    }, [dispatch, hasLinks])
    const hasSocialLinks = !event || !!event.socialLinks
    useEffect(() => {
        if (!hasSocialLinks) {
            dispatch(requestEventSocialLinks())
        }
    }, [dispatch, hasSocialLinks])
    const hasTags = !event || !!event.tags
    useEffect(() => {
        if (!hasTags) {
            dispatch(requestEventTags())
        }
    }, [dispatch, hasTags])
    useEffect(() => {
        return () => {
            dispatch(setCurrentEventId(null))
        }
    }, [dispatch, organizerUrl, eventUrl])
    useEffect(() => {
        if (!event && organizerUrl && eventUrl) {
            dispatch(requestCurrentEvent({organizerUrl, eventUrl}))
        }
    }, [dispatch, event, eventUrl, organizerUrl])
    useEffect(() => {
        if (currentEventId) {
            dispatch(requestVariable({key: VAR_KEYS.EventTicketDescription, foreignKey: currentEventId.toString()}))
        }
        return () => {
            if (currentEventId) {
                dispatch(resetEventById(currentEventId))
                dispatch(setVariable({key: `${VAR_KEYS.EventTicketDescription}-${currentEventId}`, value: null}))
            }
        }
    }, [currentEventId, dispatch])
    useEffect(() => {
        if (currentNetwork && walletAddress && currentEventId) {
            setLoadingTickets(true)
            const timer = setInterval(() => {
                setLoadingTickets(true)
            }, 15000)
            return () => {
                clearInterval(timer)
                dispatch(setUserTicketsByEvent(null))
            }
        } else {
            setLoadingTickets(false)
        }
    }, [currentEventId, currentNetwork, dispatch, walletAddress])
    useEffect(() => {
        if (loadingTickets) {
            dispatch(requestUserTicketsByEvent())
        }
    }, [dispatch, loadingTickets])
    useEffect(() => {
        if (userTickets) {
            setLoadingTickets(false)
        }
    }, [userTickets])

    const showcasesList = useMemo(() => {
        if (!event || !event.showcases) {
            return null
        }

        return event.showcases.map((item) => (<ShowcaseRow key={item.id} showcase={item}/>))
    }, [event])
    const ticketsList = useMemo(() => {
        if (!userTickets) {
            return <div>{t('status.loading')}...</div>
        }

        if (userTickets.length === 0) {
            return <div>{t('status.emptyList')}</div>
        }

        return userTickets.map((item, index) => (<EventTicketItem key={index} item={item}/>))
    }, [t, userTickets])

    const addShowcaseHandler = () => {
        dispatch(setModalAddShowcaseToEvent(true))
    }
    const editEventHandler = () => {
        dispatch(setModalEditEvent(true))
    }
    const editImageHandler = () => {
        if (event?.organizer !== walletAddress) {
            return
        }

        dispatch(setModalUploadImage({
            handler: (url: string) => {
                dispatch(putEventImage(url))
            },
            title: t('modal.title.eventBanner'),
        }))
    }
    const editLinksHandler = () => {
        dispatch(setModalEditLinks(true))
    }
    const editOrganizerHandler = () => {
        if (!event || !organizerUrl || !eventUrl) {
            return
        }

        dispatch(setModalEditString({
            title: t('modal.title.edit', {name: t('form.label.organizerName')}),
            label: t('form.label.organizerName'),
            text: event.organizerTitle,
            handler: (text: string) => {
                dispatch(sendRequestWithAuth(putOrganizerTitle({organizerId: event.organizerId, title: text})))
            },
        }))
    }
    const editSocialLinksHandler = () => {
        dispatch(setModalEditSocialLinks(true))
    }
    const editTagsHandler = () => {
        dispatch(setModalEditEventTags(true))
    }
    const editTicketDescriptionHandler = () => {
        setEditingTicketDescription(true)
        setTicketDescription(eventTicketDescription || '')
    }
    const getTicketsHandler = () => {
        dispatch(setModalShowShowcases(true))
    }
/*
    const publishHandler = () => {
        if (!event) {
            return
        }

        dispatch(changeEventModeration({eventId: event.id, moderation: !event.moderation}))
    }
*/
    const saveTicketDescriptionHandler = () => {
        if (!currentEventId) {
            return
        }

        dispatch(sendVariable({
            key: VAR_KEYS.EventTicketDescription,
            foreignKey: currentEventId.toString(),
            value: ticketDescription,
        }))
        setEditingTicketDescription(false)
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/calendar'}>{t('section.calendar')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.event')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            {event === null ?
                <div className="h4">{t('status.loading')}...</div>
                :
                event === undefined ?
                    <div className="h4">{t('error.eventNotFound')}</div>
                    :
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="position-relative row mb-3 mb-sm-5 mb-lg-6">
                                <div className="col-sm">
                                    <h1 className="mb-3 pe-sm-4">{event.title}</h1>
                                    <div className="d-flex">
                                        {event.types.map(item => (
                                            <span className="badge badge-sm text-bg-primary me-1" key={item.id}>
                                                {item.title}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-auto mt-3 mt-sm-n1">
                                    <div className="event-action-btns">
                                        {event.organizer === walletAddress ?
                                            <div className="mb-sm-1">
                                                <ButtonElement
                                                    edit
                                                    type={'no-primary'}
                                                    className={'py-2'}
                                                    onClick={editEventHandler}
                                                >
                                                    <span><EditIcon/></span>
                                                </ButtonElement>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="mb-sm-1"><ShareElement/></div>
                                        <div className="mb-sm-1">
                                            <div>
                                                <AddToCalendarButton
                                                    //                                                    label={t('button.addToCalendar')}
                                                    hideTextLabelButton={true}
                                                    name={event.title}
                                                    description={`${APP_URL}/event/${event.organizerUrl}/${event.url}`}
                                                    options={['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo']}
                                                    startDate={startDate}
                                                    startTime={startTime}
                                                    endDate={endDate}
                                                    endTime={endTime}
                                                    useUserTZ={true}
                                                    customCss={'/static/css/atcb.min.css'}
                                                    lightMode={'dark'}
                                                    hideCheckmark={true}
                                                    buttonStyle={'custom'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-5">
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">{t('event.card.date')}</div>
                                        <div>{eventDate}</div>
                                    </div>
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">
                                            {t('event.card.by')}
                                            {event.organizer === walletAddress ?
                                                <>
                                                    {' '}
                                                    <ButtonElement
                                                        small
                                                        edit
                                                        type={'no-primary'}
                                                        className={'p-2'}
                                                        onClick={editOrganizerHandler}
                                                    >
                                                        <EditIcon/>
                                                    </ButtonElement>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                        <div>
                                            {ethers.utils.isAddress(event.organizerTitle) ?
                                                compactString(event.organizerTitle)
                                                :
                                                event.organizerTitle
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-7">
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">{t('event.card.location')}</div>
                                        <div>Online</div>
                                    </div>
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">
                                            {t('event.card.tags')}
                                            {event.organizer === walletAddress ?
                                                <>
                                                    {' '}
                                                    <ButtonElement
                                                        small
                                                        edit
                                                        type={'no-primary'}
                                                        className={'p-2'}
                                                        onClick={editTagsHandler}
                                                    >
                                                        <EditIcon/>
                                                    </ButtonElement>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                        <div>{event.tags?.join(', ')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 mb-6">
                                <ul className="nav nav-tabs mt-lg-6" id="eventDetails" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 0 ? 'active' : ''}`}
                                            id="event-details-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-details-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-details-tab-pane"
                                            aria-selected="true"
                                            onClick={() => {
                                                setSelectedTab(0)
                                            }}
                                        >{t('button.details')}
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 1 ? 'active' : ''}`}
                                            id="event-tickets-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-tickets-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-tickets-tab-pane"
                                            aria-selected="false"
                                            onClick={() => {
                                                setSelectedTab(1)
                                            }}
                                        >
                                            <Trans
                                                i18nKey={'button.ticketsCount'}
                                                components={[
                                                    <span className="d-none d-sm-inline"/>,
                                                    <b className="badge text-bg-success"/>
                                                ]}
                                                count={userTickets?.length || 0}
                                            />
                                            {loadingTickets ?
                                                <span className={'icon-loading-container'} style={{display: 'block'}}>
                                                    <LoadingIcon/>
                                                </span>
                                                :
                                                null
                                            }
                                        </button>
                                    </li>
                                    {event.organizer === walletAddress ?
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${selectedTab === 2 ? 'active' : ''}`}
                                                id="event-bot-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#event-bot-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="event-bot-tab-pane"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSelectedTab(2)
                                                }}
                                            >{t('button.botSettings')}
                                            </button>
                                        </li>
                                        :
                                        null
                                    }
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 3 ? 'active' : ''}`}
                                            id="event-bot-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-bot-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-bot-tab-pane"
                                            aria-selected="false"
                                            onClick={() => {
                                                setSelectedTab(3)
                                            }}
                                        >{t('button.faq')}
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className={`tab-pane ${selectedTab === 0 ? 'show active' : ''}`}
                                        id="event-details-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-details-tab"
                                        tabIndex={0}
                                    >
                                        <div className="pt-4">
                                            <div className="mb-4">
                                                {event.description?.split(/\r?\n/).map((item, index) => (
                                                    <p key={index}>{item}</p>))}
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3">
                                                    {event.organizer === walletAddress ?
                                                        t('event.connectedShowcases')
                                                        :
                                                        t('event.buyTicketsHere')
                                                    }
                                                </h3>
                                                <div className="row-table">
                                                    {showcasesList}
                                                </div>
                                                {event.organizer === walletAddress && event.showcases && event.showcases.length < 3 ?
                                                    <ButtonElement
                                                        small
                                                        outline
                                                        className={'mb-4'}
                                                        onClick={addShowcaseHandler}
                                                    >
                                                        <AddIcon/>
                                                        <span>{t('button.addShowcase')}</span>
                                                    </ButtonElement>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`tab-pane ${selectedTab === 1 ? 'show active' : ''}`}
                                        id="event-details-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-details-tab"
                                        tabIndex={1}
                                    >
                                        <div className="pt-4">
                                            <div className="mb-4">
                                                {editingTicketDescription ?
                                                    <>
                                                        <TextareaElement
                                                            value={ticketDescription}
                                                            onChange={setTicketDescription}
                                                            additionalClass={'mb-2'}
                                                            rows={5}
                                                        />
                                                        <ButtonElement
                                                            small
                                                            outline
                                                            onClick={saveTicketDescriptionHandler}
                                                        >
                                                            <span>{t('button.save')}</span>
                                                        </ButtonElement>
                                                    </>
                                                    :
                                                    <>
                                                        <AlertElement additionalClass={'mb-2'}>
                                                            {eventTicketDescription?.split(/\r?\n/).map((item, index) => (
                                                                <p key={index}>{item}</p>
                                                            ))}
                                                        </AlertElement>
                                                        {event.organizer === walletAddress && event.showcases && event.showcases.length < 3 ?
                                                            <ButtonElement
                                                                small
                                                                outline
                                                                onClick={editTicketDescriptionHandler}
                                                            >
                                                                <EditIcon className={'me-2'}/>
                                                                <span>{t('button.editText')}</span>
                                                            </ButtonElement>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {walletAddress ?
                                            <div>
                                                <h3 className="mb-3">{t('event.yourTickets')}</h3>
                                                <div className="row-table">
                                                    {ticketsList}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {event.organizer === walletAddress ?
                                        <div
                                            className={`tab-pane ${selectedTab === 2 ? 'show active' : ''}`}
                                            id="event-bot-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="event-bot-tab"
                                            tabIndex={0}
                                        >
                                            <div className="pt-4">
                                                <div className="d-flex align-items-center mb-4">
                                                    <Trans
                                                        i18nKey={'event.botSettings0'}
                                                        count={event.id}
                                                        components={[
                                                            <span className="text-muted me-2"/>,
                                                            <CopyToClipboardBlock
                                                                text={event.id}
                                                                small
                                                                additionalClass={'mt-n1'}
                                                            />
                                                        ]}
                                                    />
                                                </div>
                                                <div>
                                                    <ol className="ps-3">
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings1'}
                                                                components={[
                                                                    <span
                                                                        className="font-monospace badge text-bg-secondary"/>
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings2'}
                                                                components={[
                                                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                                                    <a href={`https://t.me/${TG_AUTH_BOT}`}
                                                                       target="_blank" rel={'noreferrer'}/>
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            {t('event.botSettings3')}
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings4'}
                                                                components={[
                                                                    <span
                                                                        className="font-monospace badge text-bg-secondary"/>,
                                                                    <CopyToClipboardBlock
                                                                        text={'/init'}
                                                                        small
                                                                        additionalClass={'mt-n1'}
                                                                    />
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings5'}
                                                                count={event.id}
                                                                components={[
                                                                    <span
                                                                        className="font-monospace badge text-bg-secondary"/>,
                                                                    <CopyToClipboardBlock
                                                                        text={`/addevent ${event.id}`}
                                                                        small
                                                                        additionalClass={'mt-n1'}
                                                                    />
                                                                ]}
                                                            />
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div
                                        className={`tab-pane ${selectedTab === 3 ? 'show active' : ''}`}
                                        id="event-bot-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-bot-tab"
                                        tabIndex={0}
                                    >
                                        <div className="pt-4">
                                            <div className="d-flex align-items-center mb-4">
                                                <Trans
                                                    i18nKey={'event.faqDescription'}
                                                    count={event.id}
                                                    components={[
                                                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                                                        <a
                                                            href={'https://docs.myshch.io/myshch/tutorials/for-event-visitors/how-to-buy-a-ticket'}
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                        />
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 ps-xl-6">
                            <div id="event-sidebar">
                                <div className="event-tickets">
                                    <div className="event-tickets__banner mb-2">
                                        {event.img !== '' ?
                                            <img
                                                src={createIpfsLink(event.img)}
                                                alt={event.title}
                                                style={{display: bannerLoaded ? 'block' : 'none'}}
                                                onLoad={() => setBannerLoaded(true)}
                                            />
                                            :
                                            null
                                        }
                                        <img
                                            src={'/static/img/event-tickets-banner.jpg'}
                                            alt={event.title}
                                            style={{display: event.img === '' || !bannerLoaded ? 'block' : 'none'}}
                                        />
                                        {event.organizer === walletAddress ?
                                            <ButtonElement
                                                small
                                                edit
                                                type={'no-primary'}
                                                className={'p-2'}
                                                onClick={editImageHandler}
                                            ><EditIcon/></ButtonElement>
                                            :
                                            null
                                        }
                                    </div>
                                    <ButtonElement className="w-100" onClick={getTicketsHandler}>
                                        {t('event.card.getTickets')}
                                    </ButtonElement>
                                </div>
                                <div className="event-socials mt-lg-3">
                                    <div className="t-tag mb-2">
                                        {t('form.label.links')}
                                        {event.organizer === walletAddress ?
                                            <ButtonElement
                                                small
                                                edit
                                                type={'no-primary'}
                                                className={'p-2'}
                                                onClick={editSocialLinksHandler}
                                            ><EditIcon/></ButtonElement>
                                            :
                                            null
                                        }
                                    </div>
                                    {event.socialLinks ?
                                        <ul className="list-socials">
                                            {event.socialLinks.map((item) => (
                                                <li key={item.id}>
                                                    <a
                                                        className="social-link"
                                                        href={`${item.network.url}${item.url}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            src={`/static/img/socials/${item.network.logo}`}
                                                            alt={item.network.title}
                                                        />
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                        :
                                        null
                                    }
                                </div>
                                <div className="event-socials mt-lg-3">
                                    <div className="t-tag mb-2">
                                        {t('form.label.links')}
                                        {event.organizer === walletAddress ?
                                            <ButtonElement
                                                small
                                                edit
                                                type={'no-primary'}
                                                className={'p-2'}
                                                onClick={editLinksHandler}
                                            ><EditIcon/></ButtonElement>
                                            :
                                            null
                                        }
                                    </div>
                                    {event.links ?
                                        event.links.map((item, index) => (
                                            <div className="mb-2" key={index}>
                                                {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                                                <a href={item.url} target="_blank" rel={'noreferrer'}>// {item.title}</a>
                                            </div>
                                        ))
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    </div>
}

export default Event
