import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
    getModalAddEventToShowcase,
    getModalAddProperties,
    getModalAddShowcaseToEvent,
    getModalAddTicketsToLazyShowcase,
    getModalAddTicketsToShowcase,
    getModalAddTicketsToShowcaseV2,
    getModalConfirmation,
    getModalCreateDisplay,
    getModalCreateEvent,
    getModalCreateGiftsCollection,
    getModalCreateLazyDisplay,
    getModalCreateMintCollection,
    getModalCreateOnChainEvent,
    getModalCreateSbtCollection,
    getModalCreateShowcaseV2,
    getModalCreateTicketLevels,
    getModalEditDisplay,
    getModalEditEvent, getModalEditEventTags,
    getModalEditLazyDisplay, getModalEditLinks,
    getModalEditOnchainEvent,
    getModalEditShowcase, getModalEditSocialLinks,
    getModalEditString,
    getModalEditTicketPrices,
    getModalError,
    getModalGenerateAIGift,
    getModalMintGifts,
    getModalMintSbt,
    getModalMintTickets,
    getModalSendToken,
    getModalSendTransactions, getModalShowShowcases, getModalUploadImage,
    getModalVideo
} from '../../store/appSlice'
import {
    ModalAddEventToShowcase,
    ModalAddProperties,
    ModalAddShowcaseToEvent,
    ModalAddTicketsToLazyShowcase,
    ModalAddTicketsToShowcase,
    ModalAddTicketsToShowcaseV2,
    ModalConfirmation,
    ModalCreateDisplay,
    ModalCreateEvent,
    ModalCreateGiftsCollection,
    ModalCreateLazyDisplay,
    ModalCreateMintCollection,
    ModalCreateOnChainEvent,
    ModalCreateSbtCollection,
    ModalCreateShowcaseV2,
    ModalCreateTicketLevels,
    ModalEditDisplay,
    ModalEditEvent, ModalEditEventTags,
    ModalEditLazyDisplay, ModalEditLinks, ModalEditOnchainEvent,
    ModalEditShowcase, ModalEditSocialLinks,
    ModalEditString,
    ModalEditTicketPrices,
    ModalError, ModalGenerateAIGift,
    ModalMintGifts,
    ModalMintSbt,
    ModalMintTickets,
    ModalSendToken,
    ModalSendTransactions, ModalShowShowcases, ModalUploadImage,
    ModalVideo
} from './index'

enum MODAL {
    addEventToShowcase,
    addProperties,
    addShowcaseToEvent,
    addTicketsToLazyShowcase,
    addTicketsToShowcase,
    addTicketsToShowcaseV2,
    confirmation,
    createDisplay,
    createEvent,
    createGiftsCollection,
    createLazyDisplay,
    createMintCollection,
    createOnChainEvent,
    createSbtCollection,
    createShowcaseV2,
    createTicketsLevels,
    editDisplay,
    editEvent,
    editEventTags,
    editLazyDisplay,
    editLinks,
    editOnchainEvent,
    editShowcase,
    editSocialLinks,
    editString,
    editTicketPrices,
    error,
    generateAIGift,
    mintGifts,
    mintSbt,
    mintTickets,
    sendToken,
    sendTransactions,
    showShowcases,
    uploadImage,
    video,
}

const ModalManager = () => {
    const [openModals, setOpenModals] = useState<number[]>([])
    const modalAddEventToShowcase = useSelector(getModalAddEventToShowcase)
    const modalAddProperties = useSelector(getModalAddProperties)
    const modalAddShowcaseToEvent = useSelector(getModalAddShowcaseToEvent)
    const modalAddTicketsToLazyShowcase = useSelector(getModalAddTicketsToLazyShowcase)
    const modalAddTicketsToShowcase = useSelector(getModalAddTicketsToShowcase)
    const modalAddTicketsToShowcaseV2 = useSelector(getModalAddTicketsToShowcaseV2)
    const modalConfirmation = useSelector(getModalConfirmation)
    const modalCreateDisplay = useSelector(getModalCreateDisplay)
    const modalCreateEvent = useSelector(getModalCreateEvent)
    const modalCreateGiftsCollection = useSelector(getModalCreateGiftsCollection)
    const modalCreateLazyDisplay = useSelector(getModalCreateLazyDisplay)
    const modalCreateMintCollection = useSelector(getModalCreateMintCollection)
    const modalCreateOnChainEvent = useSelector(getModalCreateOnChainEvent)
    const modalCreateSbtCollection = useSelector(getModalCreateSbtCollection)
    const modalCreateShowcaseV2 = useSelector(getModalCreateShowcaseV2)
    const modalCreateTicketsLevels = useSelector(getModalCreateTicketLevels)
    const modalEditDisplay = useSelector(getModalEditDisplay)
    const modalEditEvent = useSelector(getModalEditEvent)
    const modalEditEventTags = useSelector(getModalEditEventTags)
    const modalEditLazyDisplay = useSelector(getModalEditLazyDisplay)
    const modalEditLinks = useSelector(getModalEditLinks)
    const modalEditOnchainEvent = useSelector(getModalEditOnchainEvent)
    const modalEditShowcase = useSelector(getModalEditShowcase)
    const modalEditSocialLinks = useSelector(getModalEditSocialLinks)
    const modalEditString = useSelector(getModalEditString)
    const modalEditTicketPrices = useSelector(getModalEditTicketPrices)
    const modalError = useSelector(getModalError)
    const modalGenerateAIGift = useSelector(getModalGenerateAIGift)
    const modalMintGifts = useSelector(getModalMintGifts)
    const modalMintSbt = useSelector(getModalMintSbt)
    const modalMintTickets = useSelector(getModalMintTickets)
    const modalSendToken = useSelector(getModalSendToken)
    const modalSendTransactions = useSelector(getModalSendTransactions)
    const modalShowShowcases = useSelector(getModalShowShowcases)
    const modalUploadImage = useSelector(getModalUploadImage)
    const modalVideo = useSelector(getModalVideo)
    const modals = [
        modalAddEventToShowcase, modalAddProperties, modalAddShowcaseToEvent, modalAddTicketsToLazyShowcase,
        modalAddTicketsToShowcase, modalAddTicketsToShowcaseV2, modalConfirmation, modalCreateDisplay, modalCreateEvent,
        modalCreateGiftsCollection, modalCreateLazyDisplay, modalCreateMintCollection, modalCreateOnChainEvent,
        modalCreateSbtCollection, modalCreateShowcaseV2, modalCreateTicketsLevels, modalEditDisplay, modalEditEvent,
        modalEditEventTags, modalEditLazyDisplay, modalEditLinks, modalEditOnchainEvent, modalEditShowcase,
        modalEditSocialLinks, modalEditString, modalEditTicketPrices, modalError, modalGenerateAIGift, modalMintGifts,
        modalMintSbt, modalMintTickets, modalSendToken, modalSendTransactions, modalShowShowcases, modalUploadImage,
        modalVideo
    ]
    const activeModal = openModals.length > 0 ? openModals[openModals.length - 1] : -1


    useEffect(() => {
        let newState = [...openModals]
        let changed = false
        for (let i = 0; i < modals.length; i++) {
            if (modals[i]) {
                if (newState.indexOf(i) < 0) {
                    newState.push(i)
                    changed = true
                }
            } else {
                const pos = newState.indexOf(i)
                if (pos >= 0) {
                    newState.splice(pos, 1)
                    changed = true
                }
            }
        }
        if (changed) {
            setOpenModals(newState)
        }
    }, [...modals, openModals])

    return <>
        {activeModal >= 0 ? <div className={'modal-backdrop fade show'}></div> : null}
        {modalAddEventToShowcase ? <ModalAddEventToShowcase show={activeModal === MODAL.addEventToShowcase}/> : null}
        {modalAddProperties ? <ModalAddProperties show={activeModal === MODAL.addProperties}/> : null}
        {modalAddShowcaseToEvent ? <ModalAddShowcaseToEvent show={activeModal === MODAL.addShowcaseToEvent}/> : null}
        {modalAddTicketsToLazyShowcase ? <ModalAddTicketsToLazyShowcase show={activeModal === MODAL.addTicketsToLazyShowcase}/> : null}
        {modalAddTicketsToShowcase ? <ModalAddTicketsToShowcase show={activeModal === MODAL.addTicketsToShowcase}/> : null}
        {modalAddTicketsToShowcaseV2 ? <ModalAddTicketsToShowcaseV2 show={activeModal === MODAL.addTicketsToShowcaseV2}/> : null}
        {modalConfirmation ? <ModalConfirmation show={activeModal === MODAL.confirmation}/> : null}
        {modalCreateDisplay ? <ModalCreateDisplay show={activeModal === MODAL.createDisplay}/> : null}
        {modalCreateEvent ? <ModalCreateEvent show={activeModal === MODAL.createEvent}/> : null}
        {modalCreateGiftsCollection ? <ModalCreateGiftsCollection show={activeModal === MODAL.createGiftsCollection}/> : null}
        {modalCreateLazyDisplay ? <ModalCreateLazyDisplay show={activeModal === MODAL.createLazyDisplay}/> : null}
        {modalCreateMintCollection ? <ModalCreateMintCollection show={activeModal === MODAL.createMintCollection}/> : null}
        {modalCreateOnChainEvent ? <ModalCreateOnChainEvent show={activeModal === MODAL.createOnChainEvent}/> : null}
        {modalCreateSbtCollection ? <ModalCreateSbtCollection show={activeModal === MODAL.createSbtCollection}/> : null}
        {modalCreateShowcaseV2 ? <ModalCreateShowcaseV2 show={activeModal === MODAL.createShowcaseV2}/> : null}
        {modalCreateTicketsLevels ? <ModalCreateTicketLevels show={activeModal === MODAL.createTicketsLevels}/> : null}
        {modalEditDisplay ? <ModalEditDisplay show={activeModal === MODAL.editDisplay}/> : null}
        {modalEditEvent ? <ModalEditEvent show={activeModal === MODAL.editEvent}/> : null}
        {modalEditEventTags ? <ModalEditEventTags show={activeModal === MODAL.editEventTags}/> : null}
        {modalEditLazyDisplay ? <ModalEditLazyDisplay show={activeModal === MODAL.editLazyDisplay}/> : null}
        {modalEditLinks ? <ModalEditLinks show={activeModal === MODAL.editLinks}/> : null}
        {modalEditOnchainEvent ? <ModalEditOnchainEvent show={activeModal === MODAL.editOnchainEvent}/> : null}
        {modalEditShowcase ? <ModalEditShowcase show={activeModal === MODAL.editShowcase}/> : null}
        {modalEditSocialLinks ? <ModalEditSocialLinks show={activeModal === MODAL.editSocialLinks}/> : null}
        {modalEditString ? <ModalEditString show={activeModal === MODAL.editString}/> : null}
        {modalEditTicketPrices ? <ModalEditTicketPrices show={activeModal === MODAL.editTicketPrices}/> : null}
        {modalGenerateAIGift ? <ModalGenerateAIGift show={activeModal === MODAL.generateAIGift}/> : null}
        {modalMintGifts ? <ModalMintGifts show={activeModal === MODAL.mintGifts}/> : null}
        {modalMintSbt ? <ModalMintSbt show={activeModal === MODAL.mintSbt}/> : null}
        {modalMintTickets ? <ModalMintTickets show={activeModal === MODAL.mintTickets}/> : null}
        {modalSendToken ? <ModalSendToken show={activeModal === MODAL.sendToken}/> : null}
        {modalSendTransactions ? <ModalSendTransactions show={activeModal === MODAL.sendTransactions}/> : null}
        {modalShowShowcases ? <ModalShowShowcases show={activeModal === MODAL.showShowcases}/> : null}
        {modalUploadImage ? <ModalUploadImage show={activeModal === MODAL.uploadImage}/> : null}
        {modalVideo ? <ModalVideo show={activeModal === MODAL.video}/> : null}
        {modalError ? <ModalError show={activeModal === MODAL.error}/> : null}
    </>
}

export default ModalManager
