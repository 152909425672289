import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {Trans, useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getSelectedMintCollection, getShowcaseType} from '../../store/appSlice'
import {getSelectedEventName} from '../../store/eventsSlice'
import {getSelectedOrganizerName} from '../../store/organizersSlice'
import {getSelectedTicketLevelName} from '../../store/ticketsSlice'
import {ConnectWalletButton, SignAuthMessage} from '../wallet'
import {getUser} from '../../store/authSlice'
import {AlertElement, ButtonElement, InputElement, TextareaElement} from '../elements'
import {SelectEvent, SelectOrganizer, SelectTicketLevel} from '../profile'
import {AdvancedSettingsBlock, PropertiesBlock, SelectImageBlock} from '../blocks'
import {
    getBatch,
    getDescription, getImageUrl,
    getTitle,
    setBatch,
    setBatchError,
    setDescription,
    setDescriptionError, setTitle, setTitleError
} from '../../store/inputSlice'

interface propsType {
    maxBatchAmount: number
    onSubmit: () => void
}

const MintBlock = (props: propsType) => {
    const {t} = useTranslation()
    const [advancedOpened, setAdvancedOpened] = useState(false)
    const [eventError, setEventError] = useState('')
    const [levelError, setLevelError] = useState('')
    const [organizerError, setOrganizerError] = useState('')
    const eventName = useSelector(getSelectedEventName)
    const imageUrl = useSelector(getImageUrl)
    const organizerName = useSelector(getSelectedOrganizerName)
    const selectedCollection = useSelector(getSelectedMintCollection)
    const showcaseType = useSelector(getShowcaseType)
    const ticketBatch = useSelector(getBatch)
    const ticketDescription = useSelector(getDescription)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)
    const ticketName = useSelector(getTitle)
    const user = useSelector(getUser)

    const [{wallet}] = useConnectWallet()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setBatch(1))

        return () => {
            dispatch(setBatch(null))
            dispatch(setDescription(''))
            dispatch(setTitle(''))
        }
    }, [dispatch, showcaseType])
    useEffect(() => {
        return () => {
            setAdvancedOpened(true)
        }
    }, [selectedCollection])

    const checkButtonDisabled = (): boolean => {
        return eventError !== '' || levelError !== '' || organizerError !== '' || imageUrl.error.status ||
            ticketBatch.error.status || ticketDescription.error.status || ticketName.error.status
    }
    const onChangeBatchHandler = (value: string) => {
        let val = parseInt(value)
        if (value === '' || isNaN(val)) {
            dispatch(setBatchError(t('error.mustEnter', {name: t('form.label.batchAmount')})))
            dispatch(setBatch(null))
            return
        }

        dispatch(setBatchError(null))
        val = val > props.maxBatchAmount ? props.maxBatchAmount : val
        dispatch(setBatch(val))
    }
    const onChangeDescription = (value: string) => {
        if (value === '') {
            dispatch(setDescriptionError(t('error.mustEnter', {name: t('form.label.description')})))
        } else if (value.length < 3) {
            dispatch(setDescriptionError(t('error.mustBeGreater', {name: t('form.label.description'), value: 2})))
        } else {
            dispatch(setDescriptionError(null))
        }
        dispatch(setDescription(value))
    }
    const onChangeTicketName = (value: string) => {
        if (value === '') {
            dispatch(setTitleError(t('error.mustEnter', {name: t('form.label.ticketName')})))
        } else {
            dispatch(setTitleError(null))
        }
        dispatch(setTitle(value))
    }
    const submitHandler = () => {
        let error = false
        if (showcaseType !== 'Onchain') {
            if (organizerName === '') {
                error = true
                setOrganizerError(t('error.organizerNotSelected'))
            } else {
                setOrganizerError('')
            }
            if (eventName === '') {
                error = true
                setEventError(t('error.eventNotSelected'))
            } else {
                setEventError('')
            }
            if (ticketLevelName === '') {
                error = true
                setLevelError(t('error.levelNotSelected'))
            } else {
                setLevelError('')
            }

            if (error && !advancedOpened) {
                setAdvancedOpened(true)
            }
        }
        props.onSubmit()
    }

    return <>
        {showcaseType !== 'Onchain' ?
            <div className="col-lg-9 col-xl-8">
                <AdvancedSettingsBlock
                    description={[
                        {trait_type: t('word.event'), value: eventName},
                        {trait_type: t('word.ticketLevel'), value: ticketLevelName},
                    ]}
                    opened={advancedOpened}
                    openHandler={setAdvancedOpened}
                    title={t('button.organizerEventLevel')}
                >
                    <div className="mb-4">
                        <SelectOrganizer error={organizerError}/>
                    </div>
                    <div className="mb-4">
                        <SelectEvent error={eventError}/>
                    </div>
                    <div className="mb-4">
                        <SelectTicketLevel error={levelError}/>
                    </div>
                </AdvancedSettingsBlock>
            </div>
            :
            null
        }
        <SelectImageBlock/>
        <div className="row mb-3">
            <div className="col-lg-8">
                <div className="mb-4">
                    <InputElement
                        error={ticketName.error.status}
                        errorText={ticketName.error.text}
                        label={<>{t('form.label.ticketName')} <span className="text-danger">*</span></>}
                        value={ticketName.value}
                        onChange={onChangeTicketName}
                    />
                </div>
                <div className="mb-4">
                    <TextareaElement
                        error={ticketDescription.error.status}
                        errorText={ticketDescription.error.text}
                        label={<>{t('form.label.description')} <span className="text-danger">*</span></>}
                        value={ticketDescription.value}
                        onChange={onChangeDescription}
                        rows={5}
                    />
                </div>
            </div>
        </div>
        <PropertiesBlock/>
        {!showcaseType || showcaseType === 'Classic' ?
            <div className="mb-6">
                <div className="row">
                    <div className="col-6 col-lg-2">
                        <div className="mb-4">
                            <InputElement
                                error={ticketBatch.error.status}
                                errorText={ticketBatch.error.text}
                                label={<>{t('form.label.batchAmount')} <span className="text-danger">*</span></>}
                                value={ticketBatch.value === null ? '' : ticketBatch.value.toString()}
                                onChange={onChangeBatchHandler}
                                additionalClass={'w-100'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            :
            null
        }
        {checkButtonDisabled() ?
            <div className="row">
                <div className="col-lg-8">
                    <AlertElement type={'danger'}>
                        <Trans i18nKey={'error.fillAllFields'} components={[<span className="text-danger"/>]}/>
                    </AlertElement>
                </div>
            </div>
            :
            null
        }
        <div className="row mb-6">
            <div className="col-lg-3">
                {wallet ?
                    user && user.auth ?
                        <ButtonElement
                            className={'w-100'}
                            onClick={submitHandler}
                        >{t('button.mintTickets')}
                        </ButtonElement>
                        :
                        <SignAuthMessage/>
                    :
                    <ConnectWalletButton/>
                }
            </div>
        </div>
    </>
}

export default MintBlock
