import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalEditEventTags, setModalEditEventTags} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon, CrossIcon} from '../icons'
import {IModalProps} from '../../store/types'
import {putEventTags} from '../../store/tagsSlice'
import {getCurrentEventId, getEvent} from '../../store/eventsSlice'

const ModalEditEventTags = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const modal = useSelector(getModalEditEventTags)
    const [tags, setTags] = useState<string[]>(event?.tags || [])
    const [text, setText] = useState(event?.tags?.join(', ') || '')
    const [textError, setTextError] = useState('')

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalEditEventTags(false))
    }
    const delTagHandler = (index: number) => {
        const arr = tags.toSpliced(index, 1)
        setTags(arr)
        setText(arr.join(', '))
    }
    const editTextHandler = (value: string) => {
        let arr: string[] = []
        for (let item of value.split(',')) {
            const val = item.trim()
            if (val === '') {
                continue
            }

            arr.push(val.replaceAll(/\s+/g, ' ').toLowerCase())
        }
        setText(value.toLowerCase())
        setTags(arr)
    }
    const saveHandler = () => {
        if (!modal) {
            return
        }

        let error = false

        if (tags.length > 5) {
            error = true
            setTextError(t('error.mustBeLessOrEqual', {name: t('event.card.tags'), value: 5}))
        } else {
            setTextError('')
        }

        if (error) {
            return
        }

        dispatch(putEventTags(tags))
    }

    if (!modal) {
        closeModal()
        return null
    }

    if (!props.show) {
        return null
    }

    if (tags.length > 5) {
        if (textError === '') {
            setTextError(t('error.mustBeLessOrEqual', {name: t('event.card.tags'), value: 5}))
        }
    } else if (textError !== '') {
        setTextError('')
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit event tags"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.editEventTags')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pb-4">
                    <div className="mb-4">
                        <InputElement
                            value={text}
                            onChange={editTextHandler}
                            errorText={textError}
                            label={t('form.label.tags')}
                        />
                    </div>
                    <div className="d-flex flex-wrap mb-4">
                        {tags.map((item, index) => (
                            <div className="filter-chip me-1 mb-1" key={index}>
                                <span className="value">{item}</span>
                                <span
                                    className="close"
                                    onClick={() => {
                                        delTagHandler(index)
                                    }}
                                ><CrossIcon/></span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="col-12 col-md-auto">
                        <ButtonElement
                            className={'w-100'}
                            onClick={saveHandler}
                        >{t('button.save')}</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditEventTags
