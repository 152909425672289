import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalEditLinks} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {CloseIcon} from '../icons'
import {ILink, IModalProps} from '../../store/types'
import {AppDispatch} from '../../store/store'
import {getCurrentEventId, getEvent, requestEventLinks, sendEventLinks} from '../../store/eventsSlice'
import {checkUrl} from '../../utils/functions'

const ModalEditLinks = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const [links, setLinks] = useState<ILink[]>(event?.links || [])
    const [linksError, setLinksError] = useState('')

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!event?.links) {
            dispatch(requestEventLinks())
        }
    }, [dispatch, event])

    const addLinkHandler = () => {
        setLinks((prevState) => {
            let newState = [...prevState]
            newState.push({title: '', url: ''})
            return newState
        })
    }
    const closeModal = () => {
        dispatch(setModalEditLinks(false))
    }
    const deleteLinkHandler = (index: number) => {
        setLinks((prevState) => {
            let newState = [...prevState]
            newState.splice(index, 1)
            return newState
        })
    }
    const editLinkHandler = (index: number, title?: string, url?: string) => {
        setLinks((prevState) => {
            let newState = [...prevState]
            newState[index] = {...prevState[index]}
            if (title !== undefined) {
                newState[index].title = title
            }
            if (url !== undefined) {
                newState[index].url = url
            }
            return newState
        })
    }
    const saveLinks = () => {
        let error = false
        let linkError = false
        for (let item of links) {
            if (item.title === '' && item.url === '') {
                continue
            }
            if (item.title === '' || item.url === '') {
                error = true
                linkError = true
                setLinksError(t('error.mustEnter', {name: t('form.label.links')}))
                break
            }
            if (!checkUrl(item.url)) {
                error = true
                linkError = true
                setLinksError(t('error.wrong', {name: t('form.label.url')}))
                break
            }
        }
        if (!linkError) {
            setLinksError('')
        }

        if (error) {
            return
        }

        dispatch(sendEventLinks(links))
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit event links"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.eventLinks')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body">
                    {linksError !== '' ? <div className="text-danger">{linksError}</div> : null}
                    <div className="row gx-2 gx-lg-3">
                        <div className="col-6 col-lg-4">
                            <label className="form-label">{t('form.label.name')}</label>
                        </div>
                        <div className="col-6 col-lg-6">
                            <label className="form-label">{t('form.label.url')}</label>
                        </div>
                    </div>
                    {links?.map((data, index) => {
                        const {title, url} = data
                        return <div className="row gx-2 gx-lg-3" key={index}>
                            <div className="col-6 col-lg-4">
                                <div className="mb-lg-3">
                                    <InputElement
                                        value={title}
                                        placeholder={t('form.label.name')}
                                        onChange={(value) => {
                                            editLinkHandler(index, value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6 col-lg-6">
                                <div className="mb-lg-3">
                                    <InputElement
                                        value={url}
                                        placeholder={t('form.label.url')}
                                        onChange={(value) => {
                                            editLinkHandler(index, undefined, value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-auto col-lg-2">
                                <div className="mb-2 mb-lg-3">
                                    <button
                                        className="btn btn-link link-danger w-100 px-0"
                                        onClick={() => {
                                            deleteLinkHandler(index)
                                        }}
                                    >{t('button.delete')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    })}
                    <ButtonElement link className={'px-0'} onClick={addLinkHandler}>
                        {t('button.addLink')}
                    </ButtonElement>
                </div>
                <div className="modal-footer">
                    <div className="col-12 col-md-auto">
                        <ButtonElement
                            className={'w-100'}
                            onClick={saveLinks}
                        >{t('button.save')}</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditLinks
