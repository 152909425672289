import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL} from '../utils/constants'
import {checkResponse} from './appSlice'
import {TVariable, IVariables, SliceResponse} from './types'

interface VariableState {
    variables: IVariables
}

const initialState: VariableState = {
    variables: {},
}

export const requestVariable = createAsyncThunk(
    'variables/requestVariable',
    async (params: {key: string, foreignKey: string}, {dispatch}): Promise<void> => {
        const {key, foreignKey} = params
        let response: SliceResponse<{key: string, value: string | null}> = {}
        try {
            const result = await axios.get(`${API_URL}variables/${key}/${foreignKey}`)
            response.status = result.status
            response.data = {key: `${key}-${foreignKey}`, value: result.data.value}
        } catch (e: any) {
            response.defaultData = {key: `${key}-${foreignKey}`, value: null}
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setVariable(value))
        }
        dispatch(checkResponse(response))
    }
)

export const sendVariable = createAsyncThunk(
    'variables/sendVariable',
    async (params: {key: string, foreignKey: string, value: TVariable}, {dispatch, getState}): Promise<void> => {
        const {key, foreignKey, value} = params
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse<null> = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                console.log(key, foreignKey, value)
                const result = await axios.put(`${API_URL}variables/${key}/${foreignKey}`, {value}, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(requestVariable({key, foreignKey}))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const variablesSlice = createSlice({
    name: 'variables',
    initialState,
    reducers: {
        setVariable: (state, action: PayloadAction<{ key: string, value: TVariable | null }>) => {
            if (!action.payload.value) {
                let newState: IVariables = {}
                for (let key in state.variables) {
                    if (key !== action.payload.key) {
                        newState[key] = state.variables[key]
                    }
                }
                state.variables = newState
            } else {
                state.variables[action.payload.key] = action.payload.value
            }
        },
    },
})

export const getVariable = (key: string) => (state: RootState): TVariable | undefined => state.variables.variables[key]

export const {
    setVariable,
} = variablesSlice.actions

export default variablesSlice.reducer
