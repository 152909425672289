import React, {useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalShowShowcases} from '../../store/appSlice'
import {AppDispatch} from '../../store/store'
import {getCurrentEventId, getEvent} from '../../store/eventsSlice'
import {CloseIcon} from '../icons'
import {ShowcaseRowModal} from '../showcase'
import {IModalProps} from '../../store/types'
import {getShowcaseObject} from '../../store/showcaseV2Slice'

const ModalShowShowcases = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const showcaseOnject = useSelector(getShowcaseObject)

    const dispatch = useDispatch<AppDispatch>()

    const showcasesList = useMemo(() => {
        if (!event || !event.showcases) {
            return null
        }

        return event.showcases.map((item) => (<ShowcaseRowModal key={item.id} showcase={item}/>))
    }, [event, showcaseOnject])

    const closeModal = () => {
        dispatch(setModalShowShowcases(false))
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.ticketVariants')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body">
                    {showcasesList}
                </div>
            </div>
        </div>
    </div>
}

export default ModalShowShowcases
