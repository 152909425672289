import {ethers} from 'ethers'
import {MutableRefObject} from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import i18next, {t} from 'i18next'
import moment from 'moment/moment'
import {API_URL, CHAINS, ERC20_TOKENS, EVENT_TYPE, IPFS_GATEWAY, NULL_ADDRESS, WNFT_RULES} from './constants'
import {IDropdownItem, IProperty, ITicketPrice} from '../store/types'

export const checkENSName = (value: string, network: string | null): boolean => {
    if (!network) {
        return false
    }

    if (CHAINS[network].tld === '') {
        return false
    }

    const reg = new RegExp(`^[a-zA-Z0-9.]+[.]${CHAINS[network].tld}$`)
    return reg.test(value)
}

export const checkInt = (value: string, setValue: (v: string) => void, setError: (v: string) => void) => {
    if (value === '') {
        setValue(value)
        setError('')
        return
    }
    const count = parseInt(value)
    if (!isNaN(count)) {
        setValue(count.toString())
        setError('')
        return
    }
    setError('Wrong format')
}

export const checkNameString = (val: string): boolean => {
    return /^[a-zA-Z0-9-._~ ]*$/.test(val)
}

export const checkPriceFormat = (price: string, decimals: number) => {
    const dotPos = price.indexOf('.')
    if (dotPos > 0 && price.length - dotPos - 1 > decimals) {
        return false
    }

    return /^(0|[1-9]\d*)(\.\d*)?$/.test(price)
}

export const checkUrl = (val: string): boolean => {
    return /^https?:\/\/(.)+\.(.)+/.test(val)
}

export const checkUrlNameString = (val: string): boolean => {
    return /^[a-zA-Z0-9-._~]*$/.test(val)
}

export const closeDropdown = (params: {
    close?: boolean
    cursorOnDropdown: boolean
    setClosed: () => void
    setCursorOnDropdown: () => void
}) => {
    const {close, cursorOnDropdown, setClosed, setCursorOnDropdown} = params
    setTimeout(() => {
        if (close) {
            setCursorOnDropdown()
        } else if (cursorOnDropdown) {
            return
        }
        const body = document.querySelector('body')
        if (!body) {
            return
        }
        body.onclick = null
        setClosed()
    }, 50)
}

export const compactString = (str: string, chars?: number, saveFirst?: number) => {
    if (!str) {
        return str
    }
    const useChars = chars || 3
    if (saveFirst && str.length < useChars * 2 + 2 + saveFirst) {
        return str
    }
    if (str.length < useChars * 2 + 2) {
        return str
    }
    if (saveFirst) {
        return `${str.slice(0, useChars + saveFirst)}...${str.slice(-useChars)}`
    }
    return `${str.slice(0, useChars)}...${str.slice(-useChars)}`
}

export const createIpfsLink = (link: string | null): string => {
    return link?.replace('ipfs://', IPFS_GATEWAY) || ''
}

export const getChainsForDropdown = (firstEmpty?: boolean): IDropdownItem[] => {
    let chains: IDropdownItem[] = []
    if (firstEmpty) {
        chains.push({id: 'empty', name: ''})
    }
    for (let key in CHAINS) {
        chains.push({
            id: key,
            name: CHAINS[key].label,
        })
    }
    return chains.sort((a, b) => {
        return a.name === b.name ? 0 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    })
}

export const getChainsForOnboard = () => {
    let chains = []
    for (let key in CHAINS) {
        chains.push({
            id: key,
            token: CHAINS[key].token,
            label: CHAINS[key].label,
            rpcUrl: CHAINS[key].rpcUrl,
            blockExplorerUrl: CHAINS[key].blockExplorer,
        })
    }
    return chains
}

export const getDateTime = (d: Date, UTC?: boolean): {date: string, time: string} => {
    let date
    let time
    if (UTC) {
        let str = d.toISOString()
        date = str.substring(0, str.indexOf('T'))
        str = str.substring(str.indexOf('T') + 1)
        time = str.substring(0, str.indexOf('.') - 3)
    } else {
        const str = moment(d).format()
        date = str.substring(0, str.indexOf('T'))
        time = str.substring(str.indexOf('T') + 1).substring(0, 5)
    }
    return {date, time}
}

export const getDateRangeString = (start: Date, end: Date): string => {
    const startDate = getDateTime(start)
    const endDate = getDateTime(end)
    if (startDate.date === endDate.date) {
        return `${startDate.date} ${startDate.time} - ${endDate.time}`
    } else {
        return `${startDate.date} ${startDate.time} - ${endDate.date} ${endDate.time}`
    }
}

export const getDateString = (date: Date): string => {
    const dateTime = getDateTime(date)
    return `${dateTime.date} ${dateTime.time}`
}

export const getDisplayHash = (name: string): string => {
    return ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(['string'], [name]))
}

export const getEmptyPrice = (network: string | null): ITicketPrice => ({
    price: '',
    priceError: '',
    decimals: 18,
    token: network && ERC20_TOKENS[network].length > 0 ? ERC20_TOKENS[network][0] : NULL_ADDRESS,
    customContract: '',
    customContractError: ''
})

export const getEventTypeList = (): IDropdownItem[] => {
    const eventTypes: IDropdownItem[] = []
    for (let item of Object.entries(EVENT_TYPE)) {
        let [key, value] = item
        if (isNaN(Number(key)) && key !== 'Unknown') {
            eventTypes.push({id: value, name: i18next.t(`event.type.${key}`)})
        }
    }
    return eventTypes
}

export const getIdsFromSearchParams = (param: string | null): number[] =>  {
    let ids: number[] = []
    for (let item of param?.split(',') || []) {
        if (item === '') {
            continue
        }

        const id = Number(item)
        if (!isNaN(id)) {
            ids.push(id)
        }
    }
    return ids
}

export const getMonthList = (): IDropdownItem[] => {
    return [
        {id: 0, name: i18next.t('month.january')},
        {id: 1, name: i18next.t('month.february')},
        {id: 2, name: i18next.t('month.march')},
        {id: 3, name: i18next.t('month.april')},
        {id: 4, name: i18next.t('month.may')},
        {id: 5, name: i18next.t('month.june')},
        {id: 6, name: i18next.t('month.july')},
        {id: 7, name: i18next.t('month.august')},
        {id: 8, name: i18next.t('month.september')},
        {id: 9, name: i18next.t('month.october')},
        {id: 10, name: i18next.t('month.november')},
        {id: 11, name: i18next.t('month.december')},
    ]
}

export const getPublicCollectionDropdown = (): IDropdownItem => {
    return {id: 'public', name: i18next.t('button.publicCollection')}
}

export const openDropdown = (params: {
    closeMenu: () => void
    element: MutableRefObject<any>
    setOpened: () => void
}) => {
    const {closeMenu, element, setOpened} = params
    setTimeout(() => {
        const body = document.querySelector('body')
        if (!body) {
            return
        }
        body.onclick = (e: any) => {
            if (!element.current) {
                return
            }
            if (e.path && e.path.includes(element.current)) {
                return
            }
            closeMenu()
        }
    }, 100)
    setOpened()
}

export const saveMetadata = async (
    title: string,
    description: string,
    properties: IProperty[],
    file: File | null,
    url: string,
    jwt: string
): Promise<string> => {
    if (title.trim() === '') {
        throw new Error(t('error.titleEmpty'))
    }
    if (description.trim() === '') {
        throw new Error(t('error.descriptionEmpty'))
    }

    const data = new FormData()
    if (url === '') {
        if (!file) {
            throw new Error(t('error.imageNotSelected'))
        }

        data.append('file', file)
    } else {
        data.append('imageUrl', url)
    }
    data.append('description', description)
    data.append('properties', JSON.stringify(properties))
    data.append('title', title)
    const config: AxiosRequestConfig = {headers: {'authorization': `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data'}}
    const result = await axios.post(`${API_URL}upload/metadata`, data, config)
    return result.data.url
}

export const saveImage = async (
    file: File | null,
    url: string,
    jwt: string
): Promise<string> => {
    const data = new FormData()
    if (url === '') {
        if (!file) {
            throw new Error(t('error.imageNotSelected'))
        }

        data.append('file', file)
    } else {
        data.append('imageUrl', url)
    }
    const config: AxiosRequestConfig = {headers: {'authorization': `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data'}}
    const result = await axios.post(`${API_URL}upload/image`, data, config)
    return result.data.url
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function sortByBlockNum<T extends {blockNum: number, logIndex: number}>(elements: T[]): T[] {
    return elements.sort((itemA, itemB) => {
        let numA = isNaN(itemA.blockNum) ? 0 : itemA.blockNum
        let numB = isNaN(itemB.blockNum) ? 0 : itemB.blockNum
        if (numA !== numB) {
            return numB - numA
        }
        let logA = isNaN(itemA.logIndex) ? 0 : itemA.logIndex
        let logB = isNaN(itemB.logIndex) ? 0 : itemB.logIndex
        return logB - logA
    })
}

export const tokenIsSbt = (rules: number): boolean => {
    return isNaN(rules) ? false : !!(rules & WNFT_RULES.noTransfer)
}