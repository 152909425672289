import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {MediumIcon, MyShChIcon, TwitterIcon, YoutubeIcon} from '../icons'

const Footer = () => {
    const {t} = useTranslation()

    return <footer className="s-footer">
        <div className="container">
            <div className="row">
                <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-4">
                    <div className="mb-2"><b>{t('menu.contactUs')}</b></div>
                    <div className="mb-2">
                        <a href="https://t.me/menaskop" target="_blank" rel={'noreferrer'}>&#47;&#47; telegram</a>
                    </div>
                    <div className="mb-2">
                        <a href="mailto:to@myshch.io" target="_blank" rel={'noreferrer'}>&#47;&#47; to@myshch.io</a>
                    </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-4">
                    <div className="mb-2"><b>{t('menu.documentation')}</b></div>
                    <div className="mb-2">
                        <a href="https://docs.myshch.io/" target="_blank" rel={'noreferrer'}>
                            &#47;&#47; docs.myshch.io
                        </a>
                    </div>
                    <div className="mb-2">
                        <a href="https://gitlab.com/envelop/tickets/one-page-v0" target="_blank" rel={'noreferrer'}>
                            &#47;&#47; gitlab
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-5 mb-4">
                    <div className="mb-2"><b>{t('menu.social')}</b></div>
                    <ul className="s-footer__socials">
                        <li>
                            <a className="social-link" href="https://www.youtube.com/@MyShCh-io" target="_blank"
                               rel={'noreferrer'}>
                                <YoutubeIcon/>
                            </a>
                        </li>
                        <li>
                            <a className="social-link" href="https://x.com/Myshch_io" target="_blank"
                               rel={'noreferrer'}>
                                <TwitterIcon/>
                            </a>
                        </li>
                        <li>
                            <a className="social-link" href="https://medium.com/@myshch" target="_blank"
                               rel={'noreferrer'}>
                                <MediumIcon/>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-6 col-md-4 col-lg mb-3 mb-4">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="btn btn-outline-primary btn-sm rounded-pill" to={'/tickets/check'}>
                                {t('menu.checkTickets')}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-6 col-lg-auto mb-3 mb-4">
                    <div className="d-flex align-items-center">
                        <Link className="s-footer__logo" to={'/'}><MyShChIcon/></Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
