import React, {ReactNode} from 'react'

interface propsType {
    children: ReactNode
    className?: string
    disabled?: boolean
    edit?: boolean
    link?: boolean
    onClick?: () => void
    outline?: boolean
    small?: boolean
    type?: 'primary' | 'secondary' | 'no-primary'
}

const ButtonElement = (props: propsType) => {
    const classes: string[] = ['btn']
    if (props.edit) {
        classes.push('btn-edit')
    }
    if (props.small) {
        classes.push('btn-sm')
    }
    if (props.className) {
        classes.push(props.className)
    }
    if (props.link) {
        classes.push('btn-link')
    } else if (props.type !== 'no-primary') {
        classes.push(`btn-${props.outline ? 'outline-' : ''}${props.type || 'primary'}`)
    }

    return <button
        className={classes.join(' ')}
        onClick={props.onClick}
        disabled={props.disabled}
    >
        {props.children}
    </button>
}

export default ButtonElement
