import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalUploadImage, setModalUploadImage} from '../../store/appSlice'
import {ButtonElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {IModalProps} from '../../store/types'
import {SelectImageBlock} from '../blocks'
import {getImageUrl, setImageUrlError, uploadImage} from '../../store/inputSlice'

const ModalUploadImage = (props: IModalProps) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const imageUrl = useSelector(getImageUrl)
    const modal = useSelector(getModalUploadImage)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalUploadImage(null))
    }
    const saveHandler = () => {
        if (!modal) {
            return
        }

        let error = false
        if (imageUrl.value === null) {
            error = true
            dispatch(setImageUrlError(t('error.chooseImage')))
        } else {
            dispatch(setImageUrlError(null))
        }
        if (error) {
            return
        }

        dispatch(uploadImage(modal.handler))
    }

    if (!modal) {
        closeModal()
        return null
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Upload image"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{modal.title || t('modal.title.uploadImage')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pb-4">
                    <SelectImageBlock type={'modal'}/>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={saveHandler}
                    >{t('button.save')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalUploadImage
