import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {API_URL} from '../utils/constants'
import {RootState} from './store'
import {
    DBTicketLevel,
    DBTicketWithEvent,
    DBWhitelist,
    IInviteLinks,
    ITicket,
    SliceResponse,
    WhitelistedTicket
} from './types'
import {checkResponse, sendRequestWithAuth, setModalEditString} from './appSlice'
import {createIpfsLink} from '../utils/functions'

interface TicketsState {
    checkedTicketEvents: DBTicketWithEvent[] | null
    ticketInviteLinks: IInviteLinks,
    ticketLevelId: number | null
    ticketLevels: DBTicketLevel[] | null
    tickets: ITicket[] | null
    userTicketsByEvent: ITicket[] | null
    whitelist: DBWhitelist[] | null,
}

const initialState: TicketsState = {
    checkedTicketEvents: null,
    ticketInviteLinks: {},
    ticketLevelId: null,
    ticketLevels: null,
    tickets: null,
    userTicketsByEvent: null,
    whitelist: null,
}

export const applyTicket = createAsyncThunk(
    'tickets/applyTicket',
    async (params: {ticketId: number, tokenId: bigint}, {dispatch, getState}): Promise<void> => {
        const {ticketId, tokenId} = params
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse<null> = {}
        if (!jwt || !ticketId) {
            response.error = {text: i18next.t('error.jwtOrTicketNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {ticketId, tokenId: tokenId.toString()}
                const result = await axios.post(`${API_URL}tickets/use`, body, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => {
            dispatch(requestUserTicketsByEvent())
        }
        dispatch(checkResponse(response))
    }
)

export const checkTicket = createAsyncThunk(
    'tickets/checkTicket',
    async (ticket: { network: string, contract: string, tokenId: string }, {dispatch}): Promise<void> => {
        let response: SliceResponse<DBTicketWithEvent[] | null> = {}
        try {
            const result = await axios.get(`${API_URL}tickets/check/${Number(ticket.network)}/${ticket.contract}/${ticket.tokenId}`)
            response.status = result.status
            response.data = result.data.tickets
        } catch (e: any) {
            if (e.response) {
                response.status = e.response.status
                response.defaultData = null
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setCheckedTicketEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const delTicketFromWhitelist = createAsyncThunk(
    'tickets/delTicketFromWhitelist',
    async (ticket: WhitelistedTicket, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events
        const {whitelist} = state.tickets

        let response: SliceResponse<null> = {}
        if (!jwt || !selectedEventId) {
            response.error = {text: i18next.t('error.jwtEventOrLevelNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.delete(`${API_URL}tickets/${selectedEventId}/whitelist?chain=${ticket.chain}&contract=${ticket.contract}&id=${ticket.tokenId}&level=${ticket.levelId}`, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => {
            if (whitelist) {
                dispatch(requestTicketsByEvent())
            } else {
                dispatch(requestTokens())
            }
        }
        dispatch(checkResponse(response))
    }
)

export const postTicketLevel = createAsyncThunk(
    'app/postTicketLevel',
    async ({title, level}: {title: string, level: number}, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt, user} = state.auth
        const {selectedEventId} = state.events

        let response: SliceResponse<null> = {}
        if (!jwt || !user || !selectedEventId) {
            response.error = {text: i18next.t('error.jwtUserOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {level, title}
                const result = await axios.post(`${API_URL}tickets/${selectedEventId}/levels`, body, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.successCallback = () => dispatch(requestTicketLevels())
        dispatch(checkResponse(response))
    }
)

export const putTicketLevelTitle = createAsyncThunk(
    'tickets/putTicketLevelTitle',
    async (title: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events
        const {ticketLevelId} = state.tickets

        let response: SliceResponse<null> = {}
        if (!selectedEventId || !jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {title}
                const result = await axios.put(`${API_URL}tickets/${selectedEventId}/levels/${ticketLevelId}/title`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalEditString(null))
                    dispatch(setTicketLevels(null))
                    dispatch(sendRequestWithAuth(requestTicketLevels(selectedEventId)))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestInviteLink = createAsyncThunk(
    'tickets/requestInviteLink',
    async (ticketId: number, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt, user} = state.auth

        let response: SliceResponse<IInviteLinks | null> = {}
        if (!jwt || !user || isNaN(ticketId)) {
            response.error = {text: i18next.t('error.jwtUserOrTicketNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}tickets/invite/${ticketId}`, config)
                let invite: IInviteLinks = {}
                invite[ticketId] = result.data.invite
                response.status = result.status
                response.data = invite
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setInviteLinks(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestTicketLevels = createAsyncThunk(
    'tickets/requestTicketLevels',
    async (eventId: number | undefined, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt, user} = state.auth
        const {selectedEventId} = state.events
        if (eventId === undefined && selectedEventId !== null) {
            eventId = selectedEventId
        }

        let response: SliceResponse<DBTicketLevel[]> = {}
        if (!jwt || !user || eventId === undefined) {
            response.error = {text: i18next.t('error.jwtUserOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}tickets/${eventId}/levels`, config)
                let ticketLevels: DBTicketLevel[] = []
                for (let item of result.data.levels) {
                    ticketLevels.push({id: item.id, title: item.title, level: item.level})
                }
                response.status = result.status
                response.data = ticketLevels
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setTicketLevels(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestTicketsByEvent = createAsyncThunk(
    'tickets/requestTicketsByEvent',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt, user} = state.auth
        const {selectedEventId} = state.events

        let response: SliceResponse<DBWhitelist[]> = {}
        if (!jwt || !user || !selectedEventId) {
            response.error = {text: i18next.t('error.jwtUserOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}tickets/${selectedEventId}`, config)
                let whitelist: DBWhitelist[] = []
                for (let item of result.data.whitelist) {
                    whitelist.push({
                        id: Number(item.id),
                        network: `0x${Number(item.network).toString(16)}`,
                        contract: item.contract.toLowerCase(),
                        tokenId: BigInt(item.tokenId),
                        assetType: Number(item.assetType),
                        eventId: Number(item.eventId),
                        level: item.level,
                        address: item.address,
                    })
                }
                response.status = result.status
                response.data = whitelist
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setWhitelist(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestTokens = createAsyncThunk(
    'tickets/requestTokens',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        let response: SliceResponse<ITicket[] | null> = {}
        if (!currentNetwork || !walletAddress) {
            response.error = {text: i18next.t('error.networkOrWalletNotSelected')}
        } else {
            try {
                const result = await axios.get(`${API_URL}tokens/${Number(currentNetwork)}/${walletAddress}`)
                let list: ITicket[] = []
                for (let item of result.data.tokens) {
                    list.push({
                        assetType: item.assetType,
                        network: currentNetwork,
                        contract: item.contract.toLowerCase(),
                        tokenId: BigInt(item.tokenId),
                        tokenUri: createIpfsLink(item.tokenUri),
                        owner: item.owner.toLowerCase(),
                        rules: Number(item.rules),
                        blockNum: Number(item.blockNum),
                        eventId: item.eventId,
                        ticketId: item.ticketId,
                        level: item.level,
                        isTicket: item.isTicket,
                        isUsed: item.isUsed,
                    })
                }
                response.status = result.status
                response.data = list
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setTickets(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestUserTicketsByEvent = createAsyncThunk(
    'tickets/requestUserTicketsByEvent',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app
        const {currentEventId} = state.events

        let response: SliceResponse<ITicket[] | null> = {}
        if (!currentNetwork || !walletAddress || currentEventId === null) {
            response.error = {text: i18next.t('error.networkWalletOrEventNotSelected')}
        } else {
            try {
                const result = await axios.get(`${API_URL}tickets/${currentEventId}/user/${walletAddress}/${Number(currentNetwork)}`)
                let list: ITicket[] = []
                for (let item of result.data.tickets) {
                    list.push({
                        assetType: item.assetType,
                        network: item.network,
                        contract: item.contract.toLowerCase(),
                        tokenId: BigInt(item.tokenId),
                        tokenUri: item.tokenUri,
                        owner: item.owner.toLowerCase(),
                        rules: Number(item.rules),
                        blockNum: Number(item.blockNum),
                        eventId: item.eventId,
                        ticketId: item.ticketId,
                        level: item.level,
                        isTicket: item.isTicket,
                        isUsed: item.isUsed,
                    })
                }
                response.status = result.status
                response.data = list
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setUserTicketsByEvent(value))
        }
        dispatch(checkResponse(response))
    }
)

export const sendTicketToWhitelist = createAsyncThunk(
    'tickets/sendTicketToWhitelist',
    async (ticket: WhitelistedTicket, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events
        const {whitelist} = state.tickets

        let response: SliceResponse<null> = {}
        if (!jwt || !selectedEventId) {
            response.error = {text: i18next.t('error.jwtEventOrLevelNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const tickets: WhitelistedTicket[] = [{
                    ...ticket,
                    tokenId: ticket.tokenId.toString(),
                }]
                const result = await axios.post(`${API_URL}tickets/${selectedEventId}/whitelist`, {tickets}, config)
                response.status = result.status
            } catch (e: any) {
                console.log(e)
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => {
            if (whitelist) {
                dispatch(requestTicketsByEvent())
            } else {
                dispatch(requestTokens())
            }
        }
        dispatch(checkResponse(response))
    }
)

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        resetTickets: (state) => {
            let key: keyof TicketsState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCheckedTicketEvents: (state, action: PayloadAction<DBTicketWithEvent[] | null>) => {
            state.checkedTicketEvents = action.payload
        },
        setInviteLinks: (state, action: PayloadAction<IInviteLinks | null>) => {
            if (action.payload) {
                state.ticketInviteLinks = {...state.ticketInviteLinks, ...action.payload}
            }
        },
        setTicketLevelId: (state, action: PayloadAction<number | null>) => {
            state.ticketLevelId = action.payload
        },
        setTicketLevels: (state, action: PayloadAction<DBTicketLevel[] | null>) => {
            state.ticketLevels = action.payload
        },
        setTickets: (state, action: PayloadAction<ITicket[] | null>) => {
            state.tickets = action.payload
        },
        setUserTicketsByEvent: (state, action: PayloadAction<ITicket[] | null>) => {
            state.userTicketsByEvent = action.payload
        },
        setWhitelist: (state, action: PayloadAction<DBWhitelist[] | null>) => {
            state.whitelist = action.payload
        },
    },
})

export const getCheckedTicketEvents = (state: RootState): DBTicketWithEvent[] | null => state.tickets.checkedTicketEvents
export const getSelectedTicketLevel = (state: RootState): DBTicketLevel | null => {
    if (state.tickets.ticketLevels && state.tickets.ticketLevelId !== null) {
        for (let item of state.tickets.ticketLevels) {
            if (item.id === state.tickets.ticketLevelId) {
                return item
            }
        }
    }
    return null
}
export const getSelectedTicketLevelName = (state: RootState): string => {
    if (state.tickets.ticketLevels && state.tickets.ticketLevelId !== null) {
        for (let item of state.tickets.ticketLevels) {
            if (item.id === state.tickets.ticketLevelId) {
                return item.title
            }
        }
    }
    return ''
}
export const getTicketInviteLink = (ticketId: number) => (state: RootState): string | undefined => state.tickets.ticketInviteLinks[ticketId]
export const getTicketLevelId = (state: RootState): number | null => state.tickets.ticketLevelId
export const getTicketLevels = (state: RootState): DBTicketLevel[] | null => state.tickets.ticketLevels
export const getTickets = (state: RootState): ITicket[] | null => state.tickets.tickets
export const getUserTicketsByEvent = (state: RootState): ITicket[] | null => state.tickets.userTicketsByEvent
export const getWhitelist = (state: RootState): DBWhitelist[] | null => state.tickets.whitelist

export const {
    resetTickets,
    setCheckedTicketEvents,
    setInviteLinks,
    setTicketLevelId,
    setTicketLevels,
    setTickets,
    setUserTicketsByEvent,
    setWhitelist,
} = ticketsSlice.actions

export default ticketsSlice.reducer
