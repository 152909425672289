import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getShowcaseType, setModalShowShowcases, setRedirectPath} from '../../store/appSlice'
import {CHAINS} from '../../utils/constants'
import {DBShowcase} from '../../store/types'
import {ButtonElement} from '../elements'
import {ThemeContext} from '../../context'
import {getShowcase, requestShowcase} from '../../store/showcaseV2Slice'

interface ParamsType {
    showcase: DBShowcase
}

const ShowcaseRow = (params: ParamsType) => {
    const {showcase} = params
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const showcaseWithItems = useSelector(getShowcase(showcase.name))
    const showcaseType = useSelector(getShowcaseType)
    let showcaseUrl = ''
    switch (showcase.contract) {
        case CHAINS[`0x${showcase.chain.toString(16)}`].launchpadContract:
            showcaseUrl = 'showcase'
            break
        case CHAINS[`0x${showcase.chain.toString(16)}`].lazyMintingContract:
            showcaseUrl = 'lazyshowcase'
            break
        case CHAINS[`0x${showcase.chain.toString(16)}`].showcaseV2Contract:
            showcaseUrl = 'showcases'
            break
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestShowcase({showcaseName: showcase.name, network: `0x${showcase.chain.toString(16)}`}))
    }, [dispatch, showcase])

    const buyHandler = () => {
        dispatch(setRedirectPath(`/${showcaseUrl}/${showcase.chain}/${showcase.name}`))
        dispatch(setModalShowShowcases(false))
    }

    return <div className="border-bottom">
        {showcaseUrl !== '' ?
        <div className="row pt-4 pt-sm-1 align-items-center">
            <div className="pb-sm-1 col-2 col-sm-auto">
                <img
                    src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x${showcase.chain.toString(16)}`]?.iconSvg}`}
                    alt={CHAINS[`0x${showcase.chain.toString(16)}`]?.label}
                />
            </div>
            <div className="pb-sm-1 col-10 col-sm-5"><span className="mt-1">{showcase.title}</span></div>
            <div className="pb-2 pb-sm-1 col col offset-2 offset-sm-0">
                {showcaseType === 'Classic' ?
                    <>
                        <span className="text-muted">Left: </span>
                        <span>{showcaseWithItems?.items.length}</span>
                    </>
                    :
                    null
                }
            </div>
            <div className="pb-2 pb-sm-1 col-auto">
                <ButtonElement link className={'w-100'} onClick={buyHandler}>
                    {t('button.buy')}
                </ButtonElement>
            </div>
        </div>
            :
            t('error.showcaseNotFound')
        }
    </div>
}

export default ShowcaseRow
