import React, {useState} from 'react'
import {
    EmailShareButton, FacebookShareButton, GabShareButton, HatenaShareButton, InstapaperShareButton, LineShareButton,
    LinkedinShareButton, LivejournalShareButton, MailruShareButton, OKShareButton, PocketShareButton, RedditShareButton,
    TelegramShareButton, ThreadsShareButton, TumblrShareButton, TwitterShareButton, ViberShareButton, VKShareButton,
    WhatsappShareButton, WorkplaceShareButton, EmailIcon, FacebookIcon, GabIcon, HatenaIcon, InstapaperIcon, LineIcon,
    LinkedinIcon, LivejournalIcon, MailruIcon, OKIcon, PocketIcon, RedditIcon, TelegramIcon, ThreadsIcon, TumblrIcon,
    ViberIcon, VKIcon, WeiboIcon, WhatsappIcon, WorkplaceIcon, XIcon, BlueskyIcon, WeiboShareButton, BlueskyShareButton,
    FacebookShareCount, HatenaShareCount, OKShareCount, RedditShareCount, TumblrShareCount, VKShareCount
} from 'react-share'
import {ShareIcon} from '../icons'

const ShareElement = () => {
    const [open, setOpen] = useState(false)
    const currentUrl = window.location.href
    const shareCount = (count: number) => <div className="counter">{count}</div>

    const closeHandler = () => {
        const body = document.querySelector('body')
        if (body) {
            body.onclick = null
        }
        setOpen(false)
    }
    const openHandler = () => {
        if (open) {
            return
        }

        setTimeout(() => {
            const body = document.querySelector('body')
            if (body) {
                body.onclick = closeHandler
            }
        }, 100)
        setOpen(true)
    }

    return <div className={`dropdown s-share-event`}>
        <button className="btn btn-link dropdown-toggle py-2" onClick={openHandler}><span><ShareIcon/></span></button>
        <ul className={`dropdown-menu ${open ? 'show' : ''}`}>
            <div className="s-share">
                <div className="s-share__item">
                    <BlueskyShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><BlueskyIcon size={32} round={true}/></BlueskyShareButton>
                </div>
                <div className="s-share__item">
                    <EmailShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><EmailIcon size={32} round={true}/></EmailShareButton>
                </div>
                <div className="s-share__item">
                    <FacebookShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><FacebookIcon size={32} round={true}></FacebookIcon></FacebookShareButton>
                    <FacebookShareCount url={currentUrl}>{shareCount}</FacebookShareCount>
                </div>
                <div className="s-share__item">
                    <GabShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><GabIcon size={32} round={true}/></GabShareButton>
                </div>
                <div className="s-share__item">
                    <InstapaperShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><InstapaperIcon size={32} round={true}/></InstapaperShareButton>
                </div>
                <div className="s-share__item">
                    <HatenaShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><HatenaIcon size={32} round={true}/></HatenaShareButton>
                    <HatenaShareCount url={currentUrl}>{shareCount}</HatenaShareCount>
                </div>
                <div className="s-share__item">
                    <LineShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><LineIcon size={32} round={true}/></LineShareButton>
                </div>
                <div className="s-share__item">
                    <LinkedinShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><LinkedinIcon size={32} round={true}/></LinkedinShareButton>
                </div>
                <div className="s-share__item">
                    <LivejournalShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><LivejournalIcon size={32} round={true}/></LivejournalShareButton>
                </div>
                <div className="s-share__item">
                    <MailruShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><MailruIcon size={32} round={true}/></MailruShareButton>
                </div>
                <div className="s-share__item">
                    <OKShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><OKIcon size={32} round={true}/></OKShareButton>
                    <OKShareCount url={currentUrl}>{shareCount}</OKShareCount>
                </div>
                <div className="s-share__item">
                    <PocketShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><PocketIcon size={32} round={true}/></PocketShareButton>
                </div>
                <div className="s-share__item">
                    <RedditShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><RedditIcon size={32} round={true}/></RedditShareButton>
                    <RedditShareCount url={currentUrl}>{shareCount}</RedditShareCount>
                </div>
                <div className="s-share__item">
                    <TelegramShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><TelegramIcon size={32} round={true}/></TelegramShareButton>
                </div>
                <div className="s-share__item">
                    <ThreadsShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><ThreadsIcon size={32} round={true}/></ThreadsShareButton>
                </div>
                <div className="s-share__item">
                    <TumblrShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><TumblrIcon size={32} round={true}/></TumblrShareButton>
                    <TumblrShareCount url={currentUrl}>{shareCount}</TumblrShareCount>
                </div>
                <div className="s-share__item">
                    <TwitterShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><XIcon size={32} round={true}/></TwitterShareButton>
                </div>
                <div className="s-share__item">
                    <ViberShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><ViberIcon size={32} round={true}/></ViberShareButton>
                </div>
                <div className="s-share__item">
                    <VKShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><VKIcon size={32} round={true}/></VKShareButton>
                    <VKShareCount url={currentUrl}>{shareCount}</VKShareCount>
                </div>
                <div className="s-share__item">
                    <WeiboShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><WeiboIcon size={32} round={true}/></WeiboShareButton>
                </div>
                <div className="s-share__item">
                    <WhatsappShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><WhatsappIcon size={32} round={true}/></WhatsappShareButton>
                </div>
                <div className="s-share__item">
                    <WorkplaceShareButton
                        beforeOnClick={closeHandler}
                        url={currentUrl}
                    ><WorkplaceIcon size={32} round={true}/></WorkplaceShareButton>
                </div>
            </div>
        </ul>
    </div>
}

export default ShareElement
