import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL, CHAINS, NULL_ADDRESS} from '../utils/constants'
import {checkENSName} from '../utils/functions'
import {SliceResponse} from './types'
import {checkResponse} from './appSlice'

interface ENSState {
    names: IENSNames
}

interface IENSNamePair {
    name: string
    address: string
}

interface IENSNames {
    [name: string]: string
}

const initialState: ENSState = {
    names: {},
}

export const requestAddress = createAsyncThunk(
    'ens/requestAddress',
    async (address: string, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app

        let response: SliceResponse<IENSNamePair> = {}
        if (!currentNetwork) {
            response.error = {text: i18next.t('error.networkNotSelected')}
        } else if (CHAINS[currentNetwork].tld === '') {
            response.error = {text: i18next.t('error.ensNotSupported')}
        } else if (!ethers.utils.isAddress(address)) {
            response.error = {text: i18next.t('error.wrongAddress')}
        } else {
            try {
                const result = await axios.get(`${API_URL}domains/address/${Number(currentNetwork)}/${address}`)
                if (result.status !== 200) {
                    throw new Error(i18next.t('error.wrongResponse', {name: result.data.error}))
                }

                response.status = result.status
                response.data = {name: result.data.name || '', address}
            } catch (e: any) {
                response.defaultData = {name: '', address}
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setName(value))
        }
        dispatch(checkResponse(response))
    }
)
export const requestName = createAsyncThunk(
    'ens/requestName',
    async (name: string, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app

        let response: SliceResponse<IENSNamePair> = {}
        if (!currentNetwork) {
            response.error = {text: i18next.t('error.networkNotSelected')}
        } else if (CHAINS[currentNetwork].tld === '') {
            response.error = {text: i18next.t('error.ensNotSupported')}
        } else if (!checkENSName(name, currentNetwork)) {
            response.error = {text: i18next.t('error.wrongName')}
        } else {
            try {
                const result = await axios.get(`${API_URL}domains/name/${Number(currentNetwork)}/${name}`)
                if (result.status !== 200) {
                    throw new Error(i18next.t('error.wrongResponse', {name: result.data.error}))
                }

                response.status = result.status
                let addr = result.data.address?.toLowerCase() || ''
                if (!ethers.utils.isAddress(addr) || addr === NULL_ADDRESS) {
                    addr = ''
                }
                response.data = {name, address: addr}
            } catch (e: any) {
                response.defaultData = {name, address: ''}
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setName(value))
        }
        dispatch(checkResponse(response))
    }
)

export const ensSlice = createSlice({
    name: 'ens',
    initialState,
    reducers: {
        setName: (state, action: PayloadAction<IENSNamePair>) => {
            if (action.payload.name !== '') {
                state.names[action.payload.name] = action.payload.address
            }
            if (action.payload.address !== '') {
                state.names[action.payload.address] = action.payload.name
            }
        },
        setNames: (state, action: PayloadAction<IENSNames>) => {
            state.names = action.payload
        },
    },
})

export const getName = (name: string) => (state: RootState): string | undefined => state.ens.names[name]
export const getNames = (state: RootState): IENSNames => state.ens.names

export const {
    setName,
    setNames,
} = ensSlice.actions

export default ensSlice.reducer
